import * as React from "react"
import styled from 'styled-components'
import { Row } from 'react-bootstrap'
import { FormDescript, FormHeading } from "../../styles"
import media from "../../styles/media"
import { Reveal, Tween } from 'react-gsap'

const BarDiv =styled.div`
  .chart {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(100, 1fr);
    grid-row-gap: 20px;
    height: auto;
    width: 90vw;
    padding: 5px 10px;
    position: relative;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-column-start: 1;
    grid-column-end: 101;
    display: flex;
    height: 60px;
    max-width: 85vw;

    .label{
      position:absolute;
      text-align: center;
      align-self: flex-start;
      font-size: 12px;
      line-height: 12px;
      margin-top: 25px;
      right: 6vw;
    }

    .itemName {
      position: absolute;
      text-align: center;
      align-self: flex-end;
      color: #EAEAEA;
      text-transform: uppercase;
      font-size: 2vw;
      /* font-family: 'Segoe Semi'; */
      line-height: 17px;
      height: 40px;
      left: 6vw;
    }

    .bar-1 {
      border-radius: 10px;
    height: 60px;
      width: 44%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
    height: 60px;
      width: 34%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
    height: 60px;
      width: 34%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-4 {
      align-self:flex-end;
      border-radius: 10px;
    height: 60px;
      width: 26%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-5 {
      align-self:flex-end;
      border-radius: 10px;
    height: 60px;
      width: 26%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-6 {
      align-self:flex-end;
      border-radius: 10px;
    height: 60px;
      width: 18%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-7 {
      align-self:flex-end;
      border-radius: 10px;
    height: 60px;
      width: 8%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-8 {
      align-self:flex-end;
      border-radius: 10px;
    height: 60px;
      width: 8%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
  }

  ${media.tablet`
  .chart {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(100, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: none;
    height: 525px;
    width: 80vw;
    padding: 5px 10px;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-column: auto/auto;
    grid-row-start: 1;
    grid-row-end: 101;
    display: flex;
    /* align-items: end; */
    justify-content: center;
    max-width: 10vw;
    height: 525px;

    .label{
      position:absolute;
      text-align: center;
      align-self: flex-start;
      margin-top: 15px;
      right: auto;
      font-size: 24px;
      line-height: 56px;
    }

    .itemName {
      position: absolute;
      text-align: center;
      align-self: flex-end;
      color: #EAEAEA;
      text-transform: uppercase;
      height: 40px;
      bottom: -55px;
      right: auto;
      left: auto;
      font-size: 1.1vw;
      line-height: 1.25;
      max-width: 10vw;
    }

    .bar-1 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 44%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 34%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 34%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-4 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 26%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-5 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 26%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-6 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 18%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-7 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 8%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-8 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 8%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
  }
  `}

`

const BusinessGraph = () => {
  return (
    <Row className="centered">
    <div className="formHead">
        <div className="module-border-wrap">
        <FormHeading>Business function of survey participants </FormHeading>
        </div>
        <FormDescript>Over half of participants work within procurement &amp; supply chain, IT and change &amp; transformation functions.</FormDescript>
        </div>
        <Reveal>
          <div className="graphCon">
            <BarDiv>
              <div className="chart">
                <div className="bar">
                  <div className="label">22%</div>
                  <Tween from={{ height: 0 }} duration={1}>                   
                  <div className="bar-1 desk"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1}>                   
                  <div className="bar-1 mob"></div>
                  </Tween>
                  <div className="itemName">Procurement &amp; Supply Chain</div>
                </div>
                <div className="bar">
                  <div className="label">17%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={.3}>
                  <div className="bar-2 desk"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.3}>
                  <div className="bar-2 mob"></div>
                  </Tween>
                  <div className="itemName">IT</div>
                </div>
                <div className="bar">
                  <div className="label">17%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={.6}>
                  <div className="bar-3 desk"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.6}>
                  <div className="bar-3 mob"></div>
                  </Tween>
                  <div className="itemName">Change &amp; Transformation</div>
                </div>
                <div className="bar">
                  <div className="label">13%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={.9}>
                  <div className="bar-4 desk"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.9}>
                  <div className="bar-4 mob"></div>
                  </Tween>
                  <div className="itemName">Finance</div>
                </div>
                <div className="bar">
                  <div className="label">13%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={1.2}>
                  <div className="bar-5 desk"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={1.2}>
                  <div className="bar-5 mob"></div>
                  </Tween>
                  <div className="itemName">C-Suite</div>
                </div>
                <div className="bar">
                  <div className="label">9%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={1.5}>
                  <div className="bar-6 desk"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={1.5}>
                  <div className="bar-6 mob"></div>
                  </Tween>
                  <div className="itemName">Operations</div>
                </div>
                <div className="bar">
                  <div className="label">4%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={1.8}>
                  <div className="bar-7 desk"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={1.8}>
                  <div className="bar-7 mob"></div>
                  </Tween>
                  <div className="itemName">Marketing</div>
                </div>
                <div className="bar">
                  <div className="label">4%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={2.1}>
                  <div className="bar-8 desk"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={2.1}>
                  <div className="bar-8 mob"></div>
                  </Tween>
                  <div className="itemName">Digital</div>
                </div>
              </div>
            </BarDiv>
          </div>
        </Reveal>
    </Row>
  )
}

export default BusinessGraph
