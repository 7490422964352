import * as React from "react"
import { Modal, Row } from 'react-bootstrap'
import { FormDescript, FormHeading, InfoModal, LegendDiv } from "../../styles"
import { BsPlus } from 'react-icons/bs'
import { Reveal, Tween } from 'react-gsap'

import Cloud from '../../images/icons/bi_cloud-fill.svg'
import AI from '../../images/icons/ic_baseline-analytics.svg'
import RPA from '../../images/icons/mdi_robot-industrial.svg'
import IOT from '../../images/icons/clarity_internet-of-things-solid.svg'
import Other from '../../images/icons/ic_round-lightbulb.svg'
import Block from '../../images/icons/icon-park-outline_blockchain.svg'
import Three from '../../images/icons/mdi_video-3d.svg'
import styled from "styled-components"
import media from "../../styles/media"

const BarDiv = styled.div`
  .chart {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(100, 1fr) 1fr;
    grid-row-gap: 50px;
    height: 645px;
    width: 85vw;
    padding: 5px 10px;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-column-start: 1;
    grid-column-end: 101;
    display: flex;
    width:70vw;
    
    .label{
      position: absolute;
      margin-top: -28px;
      margin-bottom: 0;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #EAEAEA;
      display: flex;
      align-items: center;

      img{
        height: 22px;
        margin-bottom: 0;
      }
    }

    .bar-1 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 25%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-1b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 47%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-1c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 32%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 37%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-2b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 23%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-2c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 44%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 33%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-3b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 24%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-3c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 46%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-4 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 33%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-4b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 58%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-4c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 13%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-5 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 50%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-5b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 19%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-5c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 35%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-6 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 40%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-6b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 42%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-6c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 22%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-7 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 0%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-7b {
      /* margin-left: -1%; */
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width:0%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-7c {
      /* margin-left:-1%; */
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

  }

  ${media.laptop`
  .chart {
    margin-left: -5vw;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(100, 1fr) 1fr;
    grid-row-gap: 50px;
    height: 725px;
    width: 70vw;
    padding: 5px 10px;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-column-start: 1;
    grid-column-end: 101;
    display: flex;
    width:70vw;
    
    .label{
      position: absolute;
      margin-top: -28px;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #EAEAEA;
      display: flex;
      align-items: center;

      img{
        height: 22px;
        margin-bottom: 0;
      }
    }

    .bar-1 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 25%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-1b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 47%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-1c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 32%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 37%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-2b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 23%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-2c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 44%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 33%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-3b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 24%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-3c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 46%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-4 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 33%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-4b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 58%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-4c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 13%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-5 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 50%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-5b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 19%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-5c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 35%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-6 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 40%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-6b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 42%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-6c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 22%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-7 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 0%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-7b {
      /* margin-left: -1%; */
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width:0%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-7c {
      /* margin-left:-1%; */
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

  }
  `}

`

const Value = () => {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <Row className="centered">
        <div className="formHead">
        <div className="module-border-wrap large">
      <FormHeading>
        Which technologies will provide greatest value in the future?
        <button onClick={() => setShow(true)}><BsPlus /></button>
      </FormHeading>
      </div>
      <FormDescript>Participants consider to cloud-based technologies as a major value generator, with 82% listing these in their top 3 value drivers. Advanced Analytics and Machine Learning as well as Cloud Migration were selected as the top value drivers by 77% of respondents. This is a key driver in the migration of pre-existing systems, applications and services to cloud promoting ease of access and scalability. Advanced Analytics, Machine Learning &amp; AI (86%) were further notes as technologies that will provide the greatest value in future.</FormDescript>
      </div>
      <Reveal>
      <div className="graphCon">
        <BarDiv>
          <div className="chart">
            <div className="bar">
              <div className="label"><img src={Cloud}alt="cloud" /> &nbsp; Cloud migration</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-1"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.3}> 
                  <div className="bar-1b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.6}> 
                  <div className="bar-1c"></div>
                  </Tween>
            </div>
            <LegendDiv className="spaceLeft">
              <div className="zero">
                <div className="colourBlock zero">28%</div>
              </div>
            </LegendDiv>

            <div className="bar">
              <div className="label"><img src={AI}alt="cloud" /> &nbsp; Advanced Analytics, Machine learning &amp; AI</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-2"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.3}> 
                  <div className="bar-2b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.6}> 
                  <div className="bar-2c"></div>
                  </Tween>
            </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">29%</div>
                </div>
              </LegendDiv>

            <div className="bar">
              <div className="label"><img src={RPA}alt="cloud" /> &nbsp; Robotics Process Automation (RPA)</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-3"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.3}> 
                  <div className="bar-3b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.6}> 
                  <div className="bar-3c"></div>
                  </Tween>
            </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">13%</div>
                </div>
              </LegendDiv>

            <div className="bar">
              <div className="label"><img src={IOT}alt="cloud" /> &nbsp; Internet of Things (IoT)</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-4"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.3}> 
                  <div className="bar-4b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.6}> 
                  <div className="bar-4c"></div>
                  </Tween>
            </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">13%</div>
                </div>
              </LegendDiv>
              
            <div className="bar">
              <div className="label"><img src={Other}alt="cloud" /> &nbsp; Other</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-5"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.3}> 
                  <div className="bar-5b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.6}> 
                  <div className="bar-5c"></div>
                  </Tween>
            </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">9%</div>
                </div>
              </LegendDiv>

            <div className="bar">
              <div className="label"><img src={Block}alt="cloud" /> &nbsp; Blockchain &amp; Smart Contracts</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-6"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.3}> 
                  <div className="bar-6b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.6}> 
                  <div className="bar-6c"></div>
                  </Tween>
            </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">7%</div>
                </div>
              </LegendDiv>

            <div className="bar">
              <div className="label"><img src={Three}alt="cloud" /> &nbsp; 3-D Printing</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-7"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.3}> 
                  <div className="bar-7b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={.6}> 
                  <div className="bar-7c"></div>
                  </Tween>
            </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">1%</div>
                </div>
              </LegendDiv>

          </div>
        </BarDiv>

        <LegendDiv className="four">
          <div className="zero">
            <div className="colourBlock zero">x%</div>
            <p className="legText">Percentage of total responses</p>
          </div>
          <div className="first">
            <div className="colourBlock one"></div>
            <p className="legText">First Choice</p>
          </div>
          <div className="second">
            <div className="colourBlock two"></div>
            <p className="legText">Second Choice</p>
          </div>
          <div className="third">
            <div className="colourBlock three"></div>
            <p className="legText">Third Choice</p>
          </div>
        </LegendDiv>
      </div>
      </Reveal>
    </Row>

    <InfoModal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <p className="infoModalTop">
          <span>Advanced Analytics, ML &amp; AI Cloud Migration &amp; the Internet of Things</span> are considered leading technologies that will add the greatest value in future.
        </p>
        <ul className="infoModalList">
          <li>
          Our data shows that companies expect to derive a significant portion of value from a set of core and similar technologies across different industries. The examples below support this observation.
          </li>
          <li>
          The Head of Digital Channels at one of South Africa’s largest retail <span>banks identified Cloud Migration, Advanced Analytics, ML &amp; AI</span> as those set to bring the greatest value in digitising operations and improving the bank’s digital channels. In addition, we note that other open-source tech platforms or solutions are set to generate value in this organisation and perhaps across the financial services sector.
          </li>
          <li>
          The group CIO of a large petrochemical organisation foresees <span>Advanced Analytics, ML &amp; AI, IoT, and Cloud Migration</span> contributing most of the digitalisation value over the next 1-3 years as the organisation progresses on its digitalisation journey.
          </li>
          <li>
          Perspectives from a <span>supply chain traceability solutions</span> provider revealed interesting insights on digitalisation technologies. Expectedly, most of the digitalisation value in this space is expected to come from <span>IoT, Advanced Analytics, and ML &amp; AI</span>. However, <span>Blockchain &amp; Smart Contracts</span> is another identified driver of digitalisation value for their business. Presumably, the choice of this technology is driven by the nature of the business i.e. the nature of the targeted business problems.
          </li>
          <li>
          In healthcare a similar trend was observed, over the next 1-3 years <span>IoT, Blockchain &amp; Smart Contracts, Advanced Analytics, ML and AI, and Cloud Migration</span> technologies are expected yield most of the digitalisation value.
          </li>
          <li>
          Considering the selected examples above, the choice of the core technologies set to bring most of the value differs slightly from industry to industry. We see financial services players leaning towards Cloud Migration, Advanced Analytics, while players in industrial operations (e.g. manufacturing, supply chain &amp; logistics) tend to favour IoT and blockchain. It is clear the choice of technologies is largely driven by industry and the targeted digitalisation use cases and expected value.
          </li>
        </ul>
      </Modal.Body>
    </InfoModal>
    </>
  )
}

export default Value
