import * as React from "react"
import styled from 'styled-components'
import { Col, Container, Row, Modal } from 'react-bootstrap'
import { Body, GreenText, Heading, IntroHead, InfoModal } from "../styles"
import media from '../styles/media'

import { BsPlus } from 'react-icons/bs'

import LongChart from '../images/chart-long.png'
import { Reveal, Tween } from "react-gsap"

const ArchDiv = styled(Container)`

    .chartLong {
        margin: 30px 0;
        overflow: scroll;

        img {
            height: 500px;
            max-width: fit-content;
        }
    }

    ${media.laptop`
        .chartLong {
            margin: 100px 0 50px;
            
            img {
                max-width: calc(100vw - 60px);
                height: auto;
            }
        }
        
    `}
`

const Archetypes = () => {
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
        <ArchDiv>
            <Row>
                <Col md={6} xs={12} className="left">
                    <IntroHead>Digital Archetypes</IntroHead>
                    {/* <Reveal> */}
                    <Heading>
                        <div className="line">
                            <Tween to={{marginTop: '0'}} duration={.5}>
                            <span><b>Overview of key </b></span>
                            </Tween>
                        </div>
                        <div className="line">
                            <Tween to={{marginTop: '0'}} duration={.5} delay={.4}>
                            <span><b>findings across</b></span>
                            </Tween>
                        </div>
                        <div className="line">
                            <Tween to={{marginTop: '0'}} duration={.5} delay={.8}>
                            <span>digital archetypes</span>
                            </Tween>
                        </div>
                    </Heading>
                    {/* </Reveal> */}
                </Col>
                <Col md={6} xs={12}>
                    <Body className="topSpaceLg max">
                    By grouping firms into key archetypes and understanding their priorities and challenges, it is possible to derive key learnings across the digital implementation journey. This analysis revealed that firms just starting their digital journey focus on ‘getting the basics in place’ and obtaining buy in from the organisation – both from a funding and operational perspective. 
                    </Body> 
                    <Body className="max">
                    Once these are secured and focus begins to shift to more advanced technologies, the complexity of governance and controls increases, often observed as a key barrier to implementation. However, the impact of these can be minimized through dedicated and accountable senior leadership supported by a dedicated PMO. 
                    </Body> 
                    <Body className="max">
                    A key insight and differentiator across the archetypes was the prioritisation of strategic partnerships/ecosystems by those close to the end of their digital journey.
                    </Body>
                </Col>
            </Row>
            <Row style={{position: 'relative'}}>
                <div className="chartLong">
                    <img src={LongChart} alt="long" />
                </div>

                <button className="archButt" onClick={() => setShow(true)}><BsPlus /></button>
            </Row>
        </ArchDiv>

        <InfoModal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
            <p className="infoModalTop">
            <span><b>Key Insights</b></span>
            </p>
            <ul className="infoModalList">
                <li>
                Late adopters appear to be prioritising the digitalisation of core processes to facilitate efficient processes
                </li>
                <li>
                As indicated by the technologies prioritised by patient builders and early adopters, once the basics have been digitised, it is possible to shift to more complex technologies such as advanced analytics, ML and AI
                </li>
                <li>
                Cloud migration remains a priority across the digital implementation journey, likely explained by the sheer volume of data, applications and other business elements which require migration
                </li>
                <li>
                Organisations across each archetype. The availability of requisite skills and competencies remains a challenge
                </li>
                <li>
                Those just starting their digital journey are faced with culture transformation challenges as they shift into new ways of doing things
                </li>
                <li>
                Budgetary constraints poses a challenge for both late adopters and patient builders, likely as a result of demonstrating the business case for digital and the need to show sufficient value to  secure more funding
                </li>
                <li>
                Once these have
                </li>
                <li>
                Firms across each archetype noted the absolute importance of a dedicated, accountable senior leader across the digital journey 
                </li>
                <li>
                To support successful culture transformation and a test case to secure additional funding, late adopters prioritise a culture & change management team and the creation of an incubation model
                </li>
                <li>
                To guide the transformation and ensure targets are met, a dedicated PMO is important for both late adopters and patient builders
                </li>
                <li>
                An important insight is that early adopters (those close to fully implementing their journey) believe strategic partnerships / ecosystems are critical enablers to successful implementation. They have learned that successful digital journeys cannot be completed alone and there is a need to leverage capabilities and experience outside of their own firm
                </li>
            </ul>
            </Modal.Body>
        </InfoModal>
        </>
    )
}

export default Archetypes
