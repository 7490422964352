import * as React from "react"
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import media from '../styles/media'
import { QuoteText } from "../styles"
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const QuoteDiv = styled(Container)`
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: block;

    .hero-image {
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        position: absolute !important;
        z-index: -1;
    }

    .inner {
        display: block;
        height: 100%;
        display: flex;
        align-items: center;
    }

    ${media.tablet`
        margin: 0 150px;
        max-width: 900px;
        display: flex;

        .inner {
            display: flex;
            column-gap: 10px;
            height: auto;
        }
    `}
`

const Quote = ({quote, author}) => {
    const data = useStaticQuery(graphql`
        query {
            quoteImg: file(relativePath: { eq: "quotebg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    
    return (
        <QuoteDiv>
            <Img
                durationFadeIn={250}
                objectFit="cover"
                fluid={data.quoteImg.childImageSharp.fluid}
                className="hero-image"
            />
            <div className="inner">
            <div>
            <QuoteText>{quote}</QuoteText>
            <QuoteText className="author">{author}</QuoteText>
            </div>
            </div>
        </QuoteDiv>
    )
}

export default Quote
