import * as React from "react"
import styled from 'styled-components'
import { Container, Row } from 'react-bootstrap'
import { FormDescript, FormHeading, Heading } from "../../styles"
import media from "../../styles/media"
// import BubbleChart from '@weknow/react-bubble-chart-d3'

const BarDiv = styled.div`
  width: 100%;
  height: 100vw;
  /* background: yellow; */
  position: relative;
  margin-bottom: 100px;

  .bubble {
    background: radial-gradient(69.69% 69.69% at 75.88% 30.31%, #61AC4A 0%, #1F580D 100%);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #c1c1c1;
    padding: 5%;

    .number {
      font-size: 36px;
      line-height: 43px;
      font-family: 'Segoe Semi';
      color: #ffffff;
      margin: 0 0 5px;
    }
    .text {
      font-size: 8px;
      line-height: 10px;
      text-align: center;
      /* opacity: .5; */
      text-transform: uppercase;
      margin: 0;
      color: #EAEAEA;
      font-family: 'Segoe Semi';
    }

    &.one {
      width: 35vw;
      height: 35vw;
      top: 15%;
      left: 5vw;
    }
    &.two {
      width: 35vw;
      height: 35vw;
      top: 21%;
      left: 43vw;
    }
    &.three {
      width: 31vw;
      height: 31vw;
      top: 50%;
      left: 20vw;
    }
    &.four {
      width: 26vw;
      height: 26vw;
      top: 80%;
      left: 35vw;
    }
    &.five {
      width: 26vw;
      height: 26vw;
      top: 73%;
      left: 0vw;
    }
    &.six {
      width: 26vw;
      height: 26vw;
      top: 58%;
      left: 53vw;
    }
    &.seven {
      width: 22vw;
      height: 22vw;
      top: 85%;
      left: 68vw;
    }
    &.eight {
      width: 22vw;
      height: 22vw;
      top: 98%;
      left: 15vw;
    }
    &.nine {
      width: 22vw;
      height: 22vw;
      top: 48%;
      left: -15px;
    }
    &.ten {
      width: 22vw;
      height: 22vw; 
      top: 0%;
      left: 34vw;
    }
  }

  ${media.laptop`
    height: 50vw;
    margin-bottom: 0;

    .bubble {
      .number {
        font-size: 48px;
        line-height: 48px;
        font-family: 'Segoe Semi';
        margin: 0 0 5px;
      }
      .text {
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Segoe Semi';
        margin: 0;
      }

      &.one {
        width: 22vw;
        height: 22vw;
        top: 15%;
        left: 33vw;
      }
      &.two {
        width: 22vw;
        height: 22vw;
        top: 28%;
        left: 55vw;
      }
      &.three {
        width: 18vw;
        height: 18vw;
        top: 35%;
        left: 15vw;
      }
      &.four {
        width: 13vw;
        height: 13vw;
        top: 5%;
        left: 20vw;
      }
      &.five {
        width: 13vw;
        height: 13vw;
        top: 16%;
        left: 6vw;
      }
      &.six {
        width: 13vw;
        height: 13vw;
        top: 2%;
        left: 53vw;
      }
      &.seven {
        width: 9vw;
        height: 9vw;
        top: 10%;
        left: 68vw;
      }
      &.eight {
        width: 9vw;
        height: 9vw;
        top: 64%;
        left: 33vw;
      }
      &.nine {
        width: 9vw;
        height: 9vw;
        top: 72%;
        left: 22vw;
      }
      &.ten {
        width: 9vw;
        height: 9vw; 
        top: 58%;
        left: 47vw;
      }
    }
  `}
`

const Composition = () => {
    return (
      <Row className="centered">
      <div className="formHead">
        <div className="module-border-wrap">
        <FormHeading>Industry composition of survey participants</FormHeading>
        </div>
        <FormDescript>Participants across 10 industries were included in the market study, with a large proportion from the Financial Services and Energy &amp; Chemicals sectors. </FormDescript>
        </div>
        <div className="graphCon">
          <BarDiv>
            <div className="bubble one">
              <p className="number">22%</p>
              <p className="text">Energy &amp; Chemicals</p>
            </div>
            <div className="bubble two">
              <p className="number">22%</p>
              <p className="text">Financial Services</p>
            </div>
            <div className="bubble three">
              <p className="number">13%</p>
              <p className="text">Consumer Goods and Retail</p>
            </div>
            <div className="bubble four">
              <p className="number">9%</p>
              <p className="text">Telecommunications</p>
            </div>
            <div className="bubble five">
              <p className="number">9%</p>
              <p className="text">Healthcare</p>
            </div>
            <div className="bubble six">
              <p className="number">9%</p>
              <p className="text">Industrial</p>
            </div>
            <div className="bubble seven">
              <p className="number">4%</p>
              <p className="text">Textiles</p>
            </div>
            <div className="bubble eight">
              <p className="number">4%</p>
              <p className="text">Agriculture</p>
            </div>
            <div className="bubble nine">
              <p className="number">4%</p>
              <p className="text">Transport &amp; Logistics</p>
            </div>
            <div className="bubble ten">
              <p className="number">4%</p>
              <p className="text">Public Sector</p>
            </div>
          </BarDiv>
        </div>
      </Row>
    )
}

export default Composition