import * as React from "react"
import styled, { keyframes } from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Tween } from 'react-gsap';
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import HomeBgMob from '../images/bg.png'
import HomeBg from '../images/blankbg.png'
import media from "../styles/media"
import { Body, LargeHeading } from "../styles"

const fade = keyframes`
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
`;

const HomeCon = styled(Container)`
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background: url(${HomeBgMob});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        z-index: 2;
    }

    .cls-1 {
        /* fill: #5ea14a; */
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        background: #0b0b0b;
        width: 100%;
        height: 100%;
        z-index: 3;


        &.hidden {
            z-index: -1;
        }
    }

    ${media.tablet`
        padding: var(--gutter-m);

        .bodytext {
            max-width: 500px;
        }
        
    `}

    ${media.laptop`
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: transparent;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center bottom;

        svg {
            display: block;
        }
    `}
`

const LoadDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #0b0b0b;
    z-index: 999;
    overflow: hidden;
    height: 100vh;
    .hero-image {
        opacity: 0;
        min-height:100vh;
        animation: ${fade} 3s linear forwards;
        animation-delay: 2s;
    }
    transition: all .3s linear;


    &.hidden {
        z-index: 1;
    }

    svg {
        animation: ${fade} .5s linear forwards;
        animation-delay: 1s;
        /* display: none; */
        position: absolute;
        height: 100%;
        left: 0;
        bottom: -5vh;

        path {
        stroke: #5ea14a;
        fill: transparent;
        stroke-width: 3.5;
        }

        &.top {
            g {
                path {
                    opacity: .7;
                }
            }
        }
    }
`

const Home = () => {
    const [showOverlay, setShowOverlay]= React.useState(true)

    const data = useStaticQuery(graphql`
        query {
            homeImg: file(relativePath: { eq: "blankbg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    setTimeout(() => {
        setShowOverlay(false)
        console.log('hide overlay');
    }, 6000);

    console.log(
        showOverlay
    );

    return (
        <>
        <HomeCon>
            <Tween from={{opacity: 0}} delay={8} duration={2}>
            <Body className="upper top">NEXT-GEN OPERATIONS</Body>
            <LargeHeading>The State of <b>Digitalisation</b> in South Africa 2021</LargeHeading>
            <Body className="bodytext home">Welcome to the first annual State of Digitalisation Survey, where we gather and present insights on how digitalisation is redefining our future of work.</Body>
            </Tween>

            {/* <div className={showOverlay ? 'overlay hidden' : 'overlay shown'}></div> */}

        </HomeCon>


        <LoadDiv className={showOverlay ? 'shown' : 'hidden'}>
            <Img
                durationFadeIn={250}
                objectFit="cover"
                fluid={data.homeImg.childImageSharp.fluid}
                className="hero-image"
            />

            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080" className={showOverlay ? 'bottom' : 'top'}>
        
            <g>
                <Tween
                    duration={8}
                    delay={1}
                    from={{
                        svgDraw: 0,
                    }}
                    to={{
                        svgDraw: 1,
                    }}
                >
                <path className="cls-1" d="M-57.74,441.37c50.67-37.51,118.51-45.2,178.72-30,60.25,15,116.3,50.51,152.92,100.86-39.54-47.4-94.36-81.23-154.12-96C60.31,401.08-5.58,407.76-57.74,441.37Z"/>
                <path className="cls-1" d="M235.06,605.86c51.16-69,139.39-119.22,226.87-101.07,28.54,5.4,55.78,16.11,81.5,29.33-26.53-11.29-53.88-20.88-82.17-25.48-86.81-15.73-171.15,32.84-226.2,97.22Z"/>
                <path className="cls-1" d="M318.75,647.06c19.15-44.18,70.22-68.64,116.79-56.45a96.77,96.77,0,0,1,41,21.23c-25.28-17-57-23.69-86.65-17.06s-55.52,26.15-71.17,52.28Z"/>
                <path className="cls-1" d="M502.43,644.76c10.75,11.83,20.27,24.57,29.5,37.55s17.91,26.33,25.41,40.44c-10.75-11.82-20.28-24.56-29.5-37.56s-17.9-26.33-25.41-40.43Z"/>
                <path className="cls-1" d="M115.77,628c-7.28-2.43-13-10.2-13.08-17.9a32.41,32.41,0,0,1,7.9,7.95,32.09,32.09,0,0,1,5.18,10Z"/>
                <path className="cls-1" d="M212.62,643.58c-19.75,15.36-48.59,17.67-71.06,6.95a62.31,62.31,0,0,1-19.79-14.71,103.13,103.13,0,0,0,21.31,11.12c22.57,8.3,47.66,6.5,69.54-3.36Z"/>
                <path className="cls-1" d="M191.67,629.26a71.79,71.79,0,0,0-18.33-29.55,106.41,106.41,0,0,0-13.47-11.14c-14.46-10.55-26.24-24.37-37.81-37.76-29.83-35.27-61-65.1-110.58-51.19a132.67,132.67,0,0,0-39.4,19.14c14.14-11.9,31.2-21,49.43-24.79,46.09-9.42,77.19,21.13,104.15,53.79C137,561.32,148.06,575,161.85,586c14.18,10.69,26.13,25.6,29.82,43.28Z"/>
                <path className="cls-1" d="M7.21,570.65a312.61,312.61,0,0,0,161.3,180.21C129.29,736.79,94,711.11,66,680.32,38.49,649.14,16.88,611.19,7.21,570.65Z"/>
                <path className="cls-1" d="M205.22,754.73c29.77,8.26,63.17-.88,83-24.84a86.73,86.73,0,0,0,15.37-26.58,65.83,65.83,0,0,1-12.31,29c-18.62,26.44-57,36.79-86,22.42Z"/>
                <path className="cls-1" d="M308.38,760.69c36.55,48.18,73.29,98.16,124.48,131.67,37.67,24.57,83.77,38.55,128.84,32.69A136.37,136.37,0,0,0,605,911.78a126.85,126.85,0,0,1-42.95,15.69c-45.82,7.86-93.44-5.9-132-30.93-51.52-33.94-88.31-85.07-121.74-135.85Z"/>
                <path className="cls-1" d="M792.33,672.32c37.69-9.6,76.25-15.54,114.88-19.75a1134.22,1134.22,0,0,1,116.39-6.45c-43.39,4.53-87,8.07-130.31,13-33.74,3.73-67.35,8.28-101,13.23Z"/>
                <path className="cls-1" d="M1080,643.31c16.78-2.14,33.61-2.76,50.48-3s33.72.18,50.54,2c-16.78,2.15-33.62,2.77-50.49,3s-33.71-.2-50.53-2Z"/>
                <path className="cls-1" d="M1205.93,645.23c6.92-1.84,14-2,21-1.77a75.76,75.76,0,0,1,20.76,3.55c-7,1.63-14,1.71-21,1.44a86.33,86.33,0,0,1-20.77-3.22Z"/>
                <path className="cls-1" d="M673.51,884c144.51-52.85,305.9-63,456.05-29.61,7.34,1.77,22.58,5.51,29.94,7.37-7.64-1.47-22.61-4.51-30.2-6.07a1031.14,1031.14,0,0,0-122.11-14.46,1010.69,1010.69,0,0,0-122.82,1.56A1011.25,1011.25,0,0,0,762.78,860.5c-14.92,3.21-45,10.63-59.75,14.73-7.22,2.2-22.25,6.67-29.52,8.8Z"/>
                <path className="cls-1" d="M700.82,897c11.43,6.29,20,16.63,26.83,27.54,6.82,11.08,12.38,22.61,19,33.61a557.65,557.65,0,0,0,48.64,71.31,411,411,0,0,0,108.13,94.1A324.49,324.49,0,0,1,839,1081c-29.75-24.92-54.81-55.19-76-87.61A562.36,562.36,0,0,1,730,934.24c-7.11-14.17-15.75-28.33-29.22-37.26Z"/>
                <path className="cls-1" d="M1382.16,657.94C1519.24,671.88,1656,692.19,1790,725c68.5,17.17,136.62,37.51,201,66.85,10.48,4.83,22.18,10.72,32.39,16.13-10.44-5-22.26-10.47-32.87-15-65-27.3-133.18-46.69-201.64-63.33-133.82-32.15-270.36-53.11-406.66-71.69Z"/>
                <path className="cls-1" d="M566.22,763.43a27.39,27.39,0,0,1,20.66-10.25,27.34,27.34,0,0,1-20.66,10.25Z"/>
                <path className="cls-1" d="M347.55,744.53c-16.08-13.87-27.73-35.95-30-57.07,4.86,9.72,9.25,19.37,14.32,28.9s10.45,18.66,15.72,28.17Z"/>
                <path className="cls-1" d="M222.26,626.81c-8.29,30.64-40.83,53.35-72.45,50.51a100.5,100.5,0,0,0,72.45-50.51Z"/>
                <path className="cls-1" d="M170.59,418.44c29,4.1,58,18.93,78.22,40.07-12.61-7.7-25-15.26-38.15-21.92s-26.46-12.44-40.07-18.15Z"/>
                <path className="cls-1" d="M47.44,395.61c-13.92-7.12-28-13.43-42.83-18.12s-29.92-7.79-45.38-10.08c31.25-3,64.48,7.64,88.21,28.2Z"/>
                <path className="cls-1" d="M352.76,590.93c12.54-14.76,34.67-22.5,53.68-18.71a205.24,205.24,0,0,0-27.61,7.16,201.83,201.83,0,0,0-26.07,11.55Z"/>
                <path className="cls-1" d="M139,532.47a312.84,312.84,0,0,0,20.59,25,317.72,317.72,0,0,0,23.55,22.22c-20.2-7.79-37.7-26.55-44.14-47.22Z"/>
                <path className="cls-1" d="M189.94,593.73c8.31-1.72,16.35,8.44,12.73,16.13a77.83,77.83,0,0,0-12.73-16.13Z"/>
                <path className="cls-1" d="M286.37,696.35c-5.27,17.53-22.67,31.86-40.9,33.6a154.48,154.48,0,0,0,22.11-14.77,151.25,151.25,0,0,0,18.79-18.83Z"/>
                <path className="cls-1" d="M910.93,818.62c13.77-2.17,27.62-2.82,41.51-3.08s27.75.12,41.58,1.91c-13.77,2.19-27.62,2.84-41.51,3.09s-27.75-.13-41.58-1.92Z"/>
                <path className="cls-1" d="M1037.76,819.16c33.66-1.56,67.81.87,101.17,5.59,33.26,5,66.79,12.47,98.53,23.6-32.75-7.22-66.06-13.86-99.26-18.66s-66.92-8.09-100.44-10.53Z"/>
                <path className="cls-1" d="M1189,1101.41c-22.4-12.41-44.87-26.8-66.34-40.85-21.36-14.25-43.39-29.25-63.59-45,22.41,12.4,44.87,26.8,66.34,40.85,21.35,14.26,43.38,29.26,63.59,45Z"/>
                <path className="cls-1" d="M1026.79,989c-6.86-3-12.84-7.3-18.63-11.93s-11.11-9.7-15.47-15.8c6.86,3,12.84,7.29,18.62,11.93s11.1,9.71,15.48,15.8Z"/>
                <path className="cls-1" d="M400.45,789.55c17.22,22.85,43,38.88,71.22,43.19s57.61-3.27,80.89-19.9c-16.13,14.62-37.75,23.2-59.51,24.73-36.52,2.83-74.06-16.45-92.6-48Z"/>
                <path className="cls-1" d="M521.06,825a107.41,107.41,0,0,1-67.47-41.42A225.08,225.08,0,0,0,521.06,825Z"/>
                </Tween>
            </g>
            </svg>
            </LoadDiv>
        </>
    )
}

export default Home
