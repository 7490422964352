import * as React from "react"
import styled from 'styled-components'
import { Row } from 'react-bootstrap'
import { FormDescript, FormHeading, LegendDiv } from "../../styles"
import media from "../../styles/media";

import { Reveal, Tween } from 'react-gsap'

const BarDiv =styled.div`
  .chart {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(100, 1fr);
    grid-column-gap: 20px;
    height: 250px;
    width: 80vw;
    padding: 5px 10px;
    position: relative;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-row-start: 1;
    grid-row-end: 101;
    display: flex;
    justify-content: center;

    .label{
      position:absolute;
      text-align: center;
      align-self: flex-start;
      margin-top: 25px;
      bottom: -20px;
      font-size: 14px;
      line-height: 17px;
    }

    .itemName {
      display: none;
      position: absolute;
      text-align: center;
      align-self: flex-end;
      max-width: 10vw;
      color: #EAEAEA;
      text-transform: uppercase;
      font-size: 2vw;
      line-height: 17px;
      height: 40px;
      bottom: -45px;
    }

    .bar-1 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 17%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 31%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 26%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
    }
    .bar-4 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 13%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-5 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 13%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
  }

  ${media.laptop`
  .chart {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(100, 1fr);
    grid-column-gap: 20px;
    height: 525px;
    width: 60vw;
    padding: 5px 10px;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-row-start: 1;
    grid-row-end: 101;
    display: flex;
    justify-content: center;

    .label{
      position:absolute;
      text-align: center;
      align-self: flex-start;
      margin-top: 25px;
      /* bottom: auto; */
    }

    .itemName {
      display: block;
      font-size: 1.2vw;
    }

    .bar-1 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 17%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 31%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 26%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
    }
    .bar-4 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 13%;
      background: #0A2E46;
    }
    .bar-5 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 13%;
      background: #61AC4A;
    }
  }

`}

`

const DigitalInitiatives = () => {
  
    return (
      <Row className="centered">
      <div className="formHead">
        <div className="module-border-wrap">
          <FormHeading>Estimate value achieved thus far from digital initiatives</FormHeading>
        </div>
          <FormDescript>For over 80% of participants, digital initiatives have realised some of the target business case value. With over a quarter of participants having realised between 50%-100% of the target value. Those firms that have realised between 75% and 100% value are almost complete with implementation.</FormDescript>
          </div>
            <Reveal repeat>
          <div className="graphCon">
            <BarDiv>
              <div className="chart">
                <div className="bar">
                  <div className="label">17%</div>
                  <Tween from={{ height: 0 }} duration={1}>   
                  <div className="bar-1"></div>
                  </Tween>
                </div>
                <div className="bar">
                  <div className="label">31%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={.3}>   
                  <div className="bar-2"></div>
                  </Tween>
                </div>
                <div className="bar">
                  <div className="label">26%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={.6}>   
                  <div className="bar-3"></div>
                  </Tween>
                </div>
                <div className="bar">
                  <div className="label">13%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={.9}>   
                  <div className="bar-4"></div>
                  </Tween>
                </div>
                <div className="bar">
                  <div className="label">13%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={1.2}>   
                  <div className="bar-5"></div>
                  </Tween>
                </div>
              </div>
            </BarDiv>

            <LegendDiv className="lowest">
              <div className="first">
                <div className="colourBlock one"></div>
                <p className="legText">UNKNOWN</p>
              </div>
              <div className="second">
                <div className="colourBlock two"></div>
                <p className="legText">0%-25%</p>
              </div>
              <div className="third">
                <div className="colourBlock three"></div>
                <p className="legText">25%-50%</p>
              </div>
              <div className="fourth">
                <div className="colourBlock four"></div>
                <p className="legText">50%-75%</p>
              </div>
              <div className="fifth">
                <div className="colourBlock five"></div>
                <p className="legText">75%-100%</p>
              </div>
            </LegendDiv>
          </div>
          </Reveal>
      </Row>
    )
}

export default DigitalInitiatives
