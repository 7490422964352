import * as React from "react"
import { useForm, useStep } from "react-hooks-helper";
import styled from "styled-components";
import Conclusion from "../components/Conclusion";
import DemoBreakdown from "../components/DemoBreakdown";
import DigitalEconomy from "../components/DigitalEconomy";

import Home from "../components/Home"

import Layout from "../components/layout"
import Methodology from "../components/Methodology";
import Quote from "../components/Quote";
import Seo from "../components/seo"
import SurveyResults from "../components/SurveyResults";
import SurveyResults2 from "../components/SurveyResults2";
import Takeouts from "../components/Takeouts";
import media from "../styles/media";

import QuoteBg from '../images/quotebg.png'
import About from "../components/About";
import Archetypes from "../components/Archetypes";
import Header from "../components/header";

const InfoCon = styled.div`
  padding: 120px var(--gutter-s) 100px;
  min-height: 100vh;

  &.takeout {
    max-height: 500px;
    min-height: 500px;;
    height: 500px;
    padding:0;
  }

  .takeoutdiv {
    position: relative;
    padding: 120px 75px 0;
    max-height: calc(var(--vh, 1vh) * 100);
  }

.takeout > div {
  max-height: calc(var(--vh, 1vh) * 100);
}

.takeout > div > div {
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
}
 /*  &.takeout > div.container {
    height: 100vh !important;
  } */

  &.quote {
    padding: 100px var(--gutter-s);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${media.laptop`
    padding: 200px var(--gutter-sm) 100px;
      
    &.takeout {
      max-height: 100vh;
      padding: 0;
    }

    .takeoutdiv {
      padding: 250px 150px 100px;
      max-height: 100vh;
    }

    &.quote {
      padding: 100px var(--gutter-sm) 100px;
    }
  `}
`

const steps = [
  { id: "home" },
  { id: "step1" },
  { id: "step2" },
  { id: "step3" },
  { id: "step4" },
  { id: "step5" },
  { id: "step6" },
  { id: "step7" },
  { id: "step8" },
  { id: "step9" },
  { id: "step10" },
  { id: "step11" },
  { id: "step12" },
  { id: "step13" }
];

const IndexPage = () => {
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = { navigation, step };

  switch (id) {
    case "home":
      return (
        <Layout {...props}>
          <Header {...props}  /> 
          <Home />
        </Layout>
      )
    case "step1":
      return (
        <>
          <Layout {...props}>
            <Header {...props}  /> 
            <InfoCon>
              <DigitalEconomy />
            </InfoCon>
          </Layout>
          <p className="pageIndi">1/9 &emsp; Introduction</p>
        </>
      )
    case "step2":
      return (
        <>
          <Layout {...props}>
            <Header {...props}  /> 
            <InfoCon>
              <Methodology />
            </InfoCon>
          </Layout>
          <p className="pageIndi">2/9 &emsp; Methodology</p>
        </>
      )
    case "step3":
      return (
        <>
          <Layout {...props}>
            <InfoCon className="takeout">
              <Takeouts {...props} />
            </InfoCon>
          </Layout>
          <p className="pageIndi">3/9 &emsp; Key Market Study Insights</p>
        </>
      )
    case "step4":
      return (
        <Layout {...props}>
          <Header {...props}  /> 
          <InfoCon className="quote">
            <Quote 
              quote={'“Digital transformation is a higher order thinking, requiring a fundamental change / transformation of how things are done. Functional units use technology to create operational efficiency. All are investing in technology to make them more efficient however this is not transformational. Use technology to fix the fundamentals then scale and see results. A lot of the functional units are stuck in the fixing the fundamentals – focusing on tools rather than being outcome-based.”'} 
              author={' – Head of Digital, Large Retail Bank'} 
            />
          </InfoCon>
        </Layout>
      )
    case "step5":
      return (
        <>
          <Layout {...props}>
            <Header {...props}  /> 
            <InfoCon>
              <DemoBreakdown />
            </InfoCon>
          </Layout>
          <p className="pageIndi">4/9 &emsp; Demographic Breakdown of Participants</p>
        </>
      )
    case "step6":
      return (
        <>
          <Layout {...props}>
            <Header {...props}  /> 
            <InfoCon>
              <SurveyResults />
            </InfoCon>
          </Layout>
          <p className="pageIndi">5/9 &emsp; Survey Results: Digitalisation Strategy</p>
        </>
      )
    case "step7":
      return (
        <>
          <Layout {...props}>
            <Header {...props}  /> 
            <InfoCon>
              <SurveyResults2 />
            </InfoCon>
          </Layout>
          <p className="pageIndi">6/9 &emsp; SURVEY RESULTS: Implementation of digital STRATEGies</p>
        </>
      )
    case "step8":
      return (
        <Layout {...props}>
          <Header {...props}  /> 
          <InfoCon className="quote">
            <Quote 
              quote={`“It's also important to have senior leadership taking ownership and accountability of the digitalisation (as well as having a specific person within the senior leadership being accountable, invested and passionate with the digitalisation journey.”`} 
              author={' – Operations Manager, Chemicals Manufacturer'} 
            />
          </InfoCon>
        </Layout>
      )
    case "step9":
      return (
        <>
          <Layout {...props}>
            <Header {...props}  /> 
            <InfoCon>
              <Archetypes />
            </InfoCon>
          </Layout>
          <p className="pageIndi">7/9 &emsp; Digital Archetypes</p>
        </>
      )
    case "step10":
      return (
        <Layout {...props}>
          <Header {...props}  /> 
          <InfoCon className="quote">
            <Quote 
              quote={`“To innovate you need to adopt a start up mentality and lean governance. However, there is so much governance and red tape to navigate. There is a risk of getting overtaken by disruptors.”`} 
              author={' – Lead PMO, Large Retail Bank'} 
            />
          </InfoCon>
        </Layout>
      )
    case "step11":
      return (
        <>
          <Layout {...props}>
            <Header {...props}  /> 
            <InfoCon>
              <Conclusion />
            </InfoCon>
          </Layout>
          <p className="pageIndi">8/9 &emsp; Close out and Implications for the Future</p>
        </>
      )
    case "step12":
      return (
        <Layout {...props}>
          <Header {...props}  /> 
          <InfoCon className="quote">
            <Quote 
              quote={`“Trialling and testing products (having both PoC's and Pilot trials) in a small scale and refine to match requirements is quite crucial. Digitalisation key to solving business needs but there needs to a formalized and established process as the business can get conformable with legacy application / ways of working.”`} 
              author={' – Operations Manager, Chemicals Manufacturer'} 
            />
          </InfoCon>
        </Layout>
      )
    case "step13":
      return (
        <>
          <Layout {...props}>
            <Header {...props}  /> 
            <InfoCon>
              <About />
            </InfoCon>
          </Layout>
          <p className="pageIndi">9/9 &emsp; ABOUT NGO</p>
        </>
      )
    default:
      return (
        <Layout {...props}>
          <Header {...props}  /> 
          <Home />
        </Layout>
      );
  }
}

export default IndexPage
