import * as React from "react"
import { Modal, Row } from 'react-bootstrap'
import { FormDescript, FormHeading, InfoModal, LegendDiv } from "../../styles"

import { BsPlus } from 'react-icons/bs'

import { Reveal, Tween } from 'react-gsap'

import People from '../../images/icons/bi_people-fill.svg'
import Data from '../../images/icons/bx_bxs-data.svg'
import Tools from '../../images/icons/entypo_tools.svg'
import Brain from '../../images/icons/fa-solid_brain.svg'
import Hand from '../../images/icons/fa-solid_handshake.svg'
import Money from '../../images/icons/fa-solid_money-bill.svg'
import Grommet from '../../images/icons/grommet-icons_technology.svg'
import Uil from '../../images/icons/uil_processor.svg'
import styled from "styled-components";
import media from "../../styles/media";
 
const BarDiv = styled.div`
  .chart {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(100, 1fr) 1fr;
    grid-row-gap: 50px;
    height: 645px;
    width: 85vw;
    padding: 5px 10px;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-column-start: 1;
    grid-column-end: 101;
    display: flex;
    width:70vw;
    
    .label{
      position: absolute;
      margin-top: -28px;
      margin-bottom: 0;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #EAEAEA;
      display: flex;
      align-items: center;

      img{
        height: 22px;
        margin-bottom: 0;
      }
    }


    .bar-1 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 67%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-1b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 20%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-1c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 20%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 35%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-2b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 45%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-2c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 27%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 11%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-3b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 47%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-3c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 47%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-4 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 12%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-4b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 42%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-4c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 52%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-5 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 58%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-5b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 35%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-5c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 13%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-6 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 22%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-6b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 22%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-6c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 62%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-7 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 0%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-7b {
      /* margin-left: -1%; */
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width:35%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-7c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 69%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-8 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 0%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-8b {
      /* margin-left: -1%; */
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width:52%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-8c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 52%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

  }

  ${media.tablet`
  .chart {
    margin-left: -5vw;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(100, 1fr) 1fr;
    grid-row-gap: 50px;
    height: 745px;
    width: 70vw;
    padding: 5px 10px;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-column-start: 1;
    grid-column-end: 101;
    display: flex;
    width:70vw;

    .label{

      font-size: 14px;
      line-height: 17px;
    }


    .bar-1 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 67%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-1b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 20%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-1c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 20%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 35%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-2b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 45%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-2c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 27%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 11%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-3b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 47%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-3c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 47%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-4 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 12%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-4b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 42%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-4c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 52%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-5 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 58%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-5b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 35%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-5c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 13%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-6 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 22%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-6b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 22%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-6c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 62%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-7 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 0%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-7b {
      /* margin-left: -1%; */
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width:35%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-7c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 69%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-8 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 0%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-8b {
      /* margin-left: -1%; */
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width:52%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-8c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 52%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }
  }
  `}

`

const Barriers = () => {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return (
      <>
      <Row className="centered">
        <div className="formHead">
        <div className="module-border-wrap large">
        <FormHeading>
          Barriers organisations face when attempting to unlock digital change
          <button onClick={() => setShow(true)}><BsPlus /></button>  
        </FormHeading>
        </div>
        <FormDescript>Skills and Competency, and Culture / Mindset Transformation are considered the key barriers to implementing digital strategies with 42% of respondents identifying these as the most significant barriers. </FormDescript>
        </div>
        <Reveal>
        <div className="graphCon">
          <BarDiv>
            <div className="chart">
              <div className="bar">
                <div className="label"><img src={Tools}alt="cloud" /> &nbsp; Skills and Competency Availability</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-1"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-1b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-1c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">25%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Money}alt="cloud" /> &nbsp; Finance / Budgetary constraints</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-2"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-2b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-2c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">13%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Brain}alt="cloud" /> &nbsp; Culture/Mindset Transformation</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-3"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-3b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-3c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">17%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={People}alt="cloud" /> &nbsp; Change Management and Adoption Complexity</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-4"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-4b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-4c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">16%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Data}alt="cloud" /> &nbsp; Data Quality and Availability</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-5"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-5b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-5c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">14%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Grommet}alt="cloud" /> &nbsp; Technology Maturity</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-6"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-6b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-6c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">3%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Uil}alt="cloud" /> &nbsp; Complex Governance and Controls</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-7"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-7b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-7c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">7%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Hand}alt="cloud" /> &nbsp; Partnerships / Ecosystems</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-8"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-8b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-8c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">4%</div>
                </div>
              </LegendDiv>
            </div>
          </BarDiv>

          <LegendDiv className="lower four">
            <div className="zero">
              <div className="colourBlock zero">x%</div>
              <p className="legText">Percentage of total responses</p>
            </div>
            <div className="first">
              <div className="colourBlock one"></div>
              <p className="legText">First Choice</p>
            </div>
            <div className="second">
              <div className="colourBlock two"></div>
              <p className="legText">Second Choice</p>
            </div>
            <div className="third">
              <div className="colourBlock three"></div>
              <p className="legText">Third Choice</p>
            </div>
          </LegendDiv>
        </div>
        </Reveal>
      </Row>

      <InfoModal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <p className="infoModalTop">
            <span>Skills and Competency, and Culture / Mindset Transformation</span> are the key barriers to digitalisation.
          </p>
          <ul className="infoModalList">
            <li>
            According to the World Economic Forum, at least 133 million new roles generated because of the new division of labour between humans, machines and algorithms may emerge globally by 2022.
            </li>
            <li>
            There is a strong demand for technical skills like programming and app development, along with skills that computers can’t easily master such as creative thinking, problem-solving and negotiating. 
            </li>
            <li>
            Where technology skills may be in place, we suggest that a lack of creative imagination in linking current business problems to technology solutions is also a barrier to digitalisation. A potential hypothesis is that the lack of technical skills in the market is exacerbated by the gap in creative technology-driven problem solving particularly at scale and sustainably.
            </li>
            <li>
            More than 50% of the participants confirmed that skills and competencies is the greatest barrier in unlocking full value from their Digital journey in South Africa. . The survey indicated financial constraints and culture and mindset were placed in second and third positions respectively.
            </li>
            <li>
            So clearly, the biggest challenge for digital evolution is not the technology, rather it is the people element. Whilst in some instances, operating models and technology roadmaps are developed rapidly, the key elements of digital capability, competency and capacity are sadly underestimated. 
            </li>
            <li>
            For the Head of Strategic Projects at a <span>courier services</span> company, there is a general shortage of skills in the business which acts as a key barrier to unlocking the value in current digitalisation efforts. The skills shortage is observed across organisational levels including senior management and board members.
            </li>
            <li>
            For a <span>chemicals manufacturer</span>, challenges with training &amp; upskilling employees undermined the realisation of benefits from of digital solutions recently implemented. Speaking about culture/mindset transformation, the Operations Manager at this firm notes the need for a formalized and established [digitalisation] process to guide the transformation from legacy to new applications &amp; ways of working.
            </li>
            <li>
            The Manager at a <span>large retailer's distribution centre</span> highlights the limitations that come with well-established organisations and the tendency to get set in old ways. Coupled with limited technology skills, this mindset has become a barrier to digitalisation efforts. To overcome this barrier, training and development should be prioritised supported by an aspirational culture accepting of technology the Industrial Manager observes.
            </li>
            <li>
            Skills shortages were highlighted in the <span>banking sector</span> too. The digital lead at one of South Africa’s retail banks points out that <span>skills</span> in creativity, design, and product management are in <span>short supply</span>. Product management is the scarcest of all as there is no where to get trained for this. 
            </li>
            <li>
            With the limited capability, competency and capacity shortages coupled with an archaic or absent learning culture, fixed mindsets, and inadequate investments across digital competency/talent development programs, it is unsurprising that South Africa is lagging in the Fourth Industrial Revolution.
            </li>
          </ul>
        </Modal.Body>
      </InfoModal>
      </>
    )
}

export default Barriers