import * as React from "react"
import { Modal, Row } from 'react-bootstrap'
import { FormDescript, FormHeading, InfoModal, LegendDiv } from "../../styles"

import { BsPlus } from 'react-icons/bs'

import { Reveal, Tween } from 'react-gsap'

import People from '../../images/icons/bi_people-fill.svg'
import Person from '../../images/icons/bi_person-fill.svg'
import Cert from '../../images/icons/healthicons_i-certificate-paper.svg'
import Bars from '../../images/icons/ic_baseline-leaderboard.svg'
import Plan from '../../images/icons/mdi_strategy.svg'
import Scale from '../../images/icons/lucide_scale.svg'
import Calc from '../../images/icons/ant-design_calculator-filled.svg'
import Other from '../../images/icons/ic_round-lightbulb.svg'
import styled from "styled-components";
import media from "../../styles/media"

const BarDiv = styled.div`
  .chart {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(100, 1fr) 1fr;
    grid-row-gap: 50px;
    height: 645px;
    width:85vw;
    padding: 5px 10px;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-column-start: 1;
    grid-column-end: 101;
    display: flex;
    width:70vw;
    
    .label{
      position: absolute;
      margin-top: -28px;
      margin-bottom: 0;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #EAEAEA;
      display: flex;
      align-items: center;

      img{
        height: 22px;
        margin-bottom: 0;
      }
    }

    .bar-1 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 38%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-1b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 51%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-1c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 13%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 36%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-2b {
      margin-left: -1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 20%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-2c {
      margin-left:-1%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 49%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 62%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-3b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 27%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-3c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 16%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-4 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 43%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-4b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 31%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-4c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 31%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-5 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 43%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-5b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 45%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-5c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 16%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-6 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 33%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-6b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 52%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-6c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 19%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-7 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 0%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-7b {
      /* margin-left: -1%; */
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width:60%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-7c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 42%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-8 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 0%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-8b {
      /* margin-left: -1%; */
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width:0%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-8c {
      /* margin-left:-2%; */
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

  }

  ${media.laptop`
  .chart {
    margin-left: -5vw;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(100, 1fr) 1fr;
    grid-row-gap: 50px;
    height: 725px;
    width: 70vw;
    padding: 5px 10px;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-column-start: 1;
    grid-column-end: 101;
    display: flex;
    width:70vw;
    
    .label{
      position: absolute;
      margin-top: -28px;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #EAEAEA;
      display: flex;
      align-items: center;

      img{
        height: 22px;
        margin-bottom: 0;
      }
    }


  }
  `}

`

const Capabilities = () => {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return (
      <>
      <Row className="centered">
        <div className="formHead">
        <div className="module-border-wrap large">
        <FormHeading>
          Capabilities the organisation has that aides in the digitalisation journey
          <button onClick={() => setShow(true)}><BsPlus /></button>
        </FormHeading>
        </div>
        <FormDescript>Dedicated, accountable senior leader for the transformation is considered the key enabler for digital adoption in an Organisation with 26% of respondents citing this capability as aiding the digitalization journey. This is supported by a dedicated Program / Journey Management office with clear governance.</FormDescript>
        </div>
        <Reveal>
        <div className="graphCon">
          <BarDiv>
            <div className="chart">
              <div className="bar">
                <div className="label"><img src={Person}alt="cloud" /> &nbsp; Dedicated, Acccountable Senior Leader for the Transformation </div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-1"></div>
                  </Tween>                
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-1b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-1c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">26%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Plan}alt="cloud" /> &nbsp; Strategic Partnerships / Ecosystem </div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-2"></div>
                  </Tween>                
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-2b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-2c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">18%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Cert}alt="cloud" /> &nbsp; Dedicated PMO with clear governance </div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-3"></div>
                  </Tween>                
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-3b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-3c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">13%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Bars}alt="cloud" /> &nbsp; Flexible / Appropriate Performance Metrics  </div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-4"></div>
                  </Tween>                
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-4b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-4c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">11%</div>
                </div>
              </LegendDiv>
              
              <div className="bar">
                <div className="label"><img src={People}alt="cloud" /> &nbsp; Culture and Change Management team </div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-5"></div>
                  </Tween>                
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-5b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-5c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">11%</div>
                </div>
              </LegendDiv>
              
              <div className="bar">
                <div className="label"><img src={Scale}alt="cloud" /> &nbsp; Incubation model to define / scale Innovation</div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-6"></div>
                  </Tween>                
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-6b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-6c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">10%</div>
                </div>
              </LegendDiv>
              
              <div className="bar">
                <div className="label"><img src={Calc}alt="cloud" /> &nbsp; Clear Budgets / Investment Philosophy    </div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-7"></div>
                  </Tween>                
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-7b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-7c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">8%</div>
                </div>
              </LegendDiv>
              
              <div className="bar">
                <div className="label"><img src={Other}alt="cloud" /> &nbsp; Other  </div>
                  <Tween from={{ width: 0 }} duration={1} delay={0}> 
                  <div className="bar-8"></div>
                  </Tween>                
                  <Tween from={{ width: 0 }} duration={1} delay={0.3}> 
                  <div className="bar-8b"></div>
                  </Tween>
                  <Tween from={{ width: 0 }} duration={1} delay={0.6}> 
                  <div className="bar-8c"></div>
                  </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">3%</div>
                </div>
              </LegendDiv>

            </div>
          </BarDiv>

          <LegendDiv className="lower four">
            <div className="zero">
              <div className="colourBlock zero">x%</div>
              <p className="legText">Percentage of total responses</p>
            </div>
            <div className="first">
              <div className="colourBlock one"></div>
              <p className="legText">First Choice</p>
            </div>
            <div className="second">
              <div className="colourBlock two"></div>
              <p className="legText">Second Choice</p>
            </div>
            <div className="third">
              <div className="colourBlock three"></div>
              <p className="legText">Third Choice</p>
            </div>
          </LegendDiv>
        </div>
        </Reveal>
      </Row>

      <InfoModal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <p className="infoModalTop">
          A dedicated accountable leader was ranked as the key organisational enabler
          </p>
          <ul className="infoModalList">
            <li>
            A senior digital channels leader at one of the <span>major banks</span> in South Africa believes that all capabilities are needed holistically to support the digitalisation journey. However, <span>leadership</span> (dedicated accountable senior leader) is in the top two enablers together with culture-related capabilities. According to the Head of Strategic Change from the same bank, leaders are important on the digitalisation journey as they understand the strong ties of the digitalisation agenda to the business strategy, with clear performance scorecards. The particpants notes that in certain cases, digital transformation is initiated at low levels of an organisation and permeates upwards. In this case, teams risk losing momentum and direction. A resilient leadership is what keeps teams on the right path. To support digital transformation, resilient leadership is required, capable of weathering out any negativity from culture.
            </li>
            <li>
            Similarly, a group CIO at one of the leading <span>petrochemical</span> company asserts that a <span>dedicated senior leader</span> at the EVP level is a key enabler for digitalisation. A dedicated <span>program/journey management office</span> has also proved to be instrumental in this organisation particularly in starting &amp; supporting their digitalisation journey.
            </li>
            <li>
            A senior procurement leader in a leading <span>heavy engineering</span> company highlights the value of a <span>PMO</span> in managing project timelines and enabling teams to support and be part of the organisation’s digital journey.
            </li>
            <li>
            Our data shows a limited focus on leveraging strategic partnerships or ecosystems to achieve digitalisation goals.  To achieve faster digitalisation, organisations should include strategic partners to help the organisations scale digital transformation efforts and transfer key skills. We believe that organisations risk worsening the <span>skills challenge</span> by building localised core capabilities or digital competencies. This also comes with limited <span>digitalisation speed</span> as compared to companies which leverage strategic partners. Of the 5 organisations in advanced stages of implementation (over 70% complete), 3 identified strategic partners/ecosystems in the top three enabling capabilities. This data demonstrates the importance of leveraging the right partnerships/ecosystems to accelerate digitalisation. 
            </li>
            <li>
            Our data further shows us the importance of working with <span>partners/ecosystems</span> to maximise <span>value realisation</span> against business case targets. We note that of the few companies achieving over 50% value realisation against the target business case, 4 in 6 reported to having leaned on strategic partnerships/ecosystem on their digitalisation journey. 
            </li>
            <li>
            These observations above highlight the value partners or ecosystems bring to digitalisation in two dimensions: implementation speed and value realisation against the set business case.
            </li>
          </ul>
        </Modal.Body>
      </InfoModal>
      </>
    )
}

export default Capabilities