import { FormDescript, FormHeading } from "../../styles"
import { Row } from "react-bootstrap";
import * as React from "react";
import styled from "styled-components";
import media from "../../styles/media";
import { Reveal, Tween } from 'react-gsap'

import Journey1 from '../../images/journey1.png'
import Journey2 from '../../images/journey2.png'
import Journey3 from '../../images/journey3.png'

const BarDiv = styled.div`
  margin:50px 0 0;
  width: calc(100vw - 60px);
  height: calc(100vw - 60px);

  @keyframes fade-in {
    0%,
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  p,span {
    text-align:center;
  }

  display: flex;
  flex-direction:column;

  p{
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
      color: #EAEAEA;
    margin-top: 0px;
    margin-bottom: 0;
    font-family: 'Segoe UI';
  }

  span{
    font-size: 58px;
    line-height: 69px;
    text-align: center;
      color: #EAEAEA;
    margin-top: -20px;
    font-family: 'Segoe Semi';
  }

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.one {
    background-image: url(${Journey1});
  }
  &.two {
    background-image: url(${Journey2});
  }
  &.three {
    background-image: url(${Journey3});
  }

  ${media.tablet`

    margin:0;

    width: 30vw;
    height: 30vw;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.one {
      background-image: url(${Journey1});
    }
    &.two {
      background-image: url(${Journey2});
    }
    &.three {
      background-image: url(${Journey3});
    }
  `}
`

const JourneyFar = () => {
  
  return (
    <Row className="centered">
    <div className="formHead">
      <div className="module-border-wrap">
      <FormHeading>How far are organisations in their digital journey?</FormHeading>
      </div>
      <FormDescript>Majority of organisations are amid implementation of their digitalisation journey with the end within reach for just over 20% of participants.</FormDescript>
      </div>

      <Reveal repeat>
        <div className="graphCon three">
        <BarDiv className="one">
          {/* <ul className="chart-skills">
            <li className="one">
            </li>
          </ul> */}
          <div><p>&lt; 30% complete</p>
          <span>17%</span></div>
          </BarDiv>
          <BarDiv className="two">
          {/* <ul className="chart-skills">
            <li className="two">
            </li>
          </ul> */}
          <div><p>Between 30% and 70%​</p>
          <span>61%</span></div>
          </BarDiv>
          <BarDiv className="three">
          {/* <ul className="chart-skills">
            <li className="three">
            </li>
          </ul> */}
          <div><p>&gt; 70% complete</p>
          <span>22%</span></div>
          </BarDiv>
        </div>
      </Reveal>
    </Row>
  ) 
};

export default JourneyFar
