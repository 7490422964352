import * as React from "react"
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Body, Heading } from "../styles"
import media from '../styles/media' 
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import Team from '../images/teams.png'

import { BsEnvelope, BsLinkedin } from "react-icons/bs"
import { RiLinkedinFill } from "react-icons/ri"
import { Reveal, Tween } from "react-gsap"

const AboutDiv = styled(Container)`

    .more {
        color: white;
        text-decoration: none;
        background: var(--green);
        height: 38px;
        width: 100%;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s linear;
    }

    .sociallinks {
        display:flex;
        margin-top:20px;
    }

    .social {
        width: 34px;
        height: 34px;
        color: white;
        border: 2px solid #61AC4A;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px 0 0;
        transition: all .3s linear;
        border-radius: 50%;
    }

    .people{
        margin: 40px 0;

        &.desk {
            position: relative;
        }
    }

    .person {
        width: 135px;
    }

    ${media.laptop`
        max-height: 100vh;

        .people {
            &.desk {
                position: relative;
                height: 60vh;
                display:flex;
                justify-content:center;
                margin: 0;

            .person {
                position: absolute;
                height: 9vw;
                width: 9vw;
                margin-top: -7vw;

                &.two {
                    top: 25%;
                    left: 20%;
                }
                &.three {
                    left: 42%;
                    top: 1%;
                }
                &.four {
                    left: 67%;
                    top: 21%;
                }
                &.five {
                    top: 43%;
                    left: 46%;
                }
                &.six {
                    top: 99%;
                    left: 28%;
                }
                &.seven {
                    top: 66%;
                    left: 25%;
                }
                &.eight {
                    top: 86%;
                    left: 55%;
                }
                &.nine {
                    left: 70%;
                    top: 55%;
                }
                &.ten {
                    top: 50%;
                    left: 0;
                }
                &.eleven {
                    top: -21px;
                    left: 8%;
                }
            }
        }
        }

        .contactDiv {
            display: flex;
            align-items: center;
        }

        .more {
            width: 221px;
            height: 39px;
            font-size: 16px;
            line-height: 21px;
            margin-right: 50px;

            &:hover {
                color: white;
                background: #303333;
            }
        }

        .sociallinks {
            margin:0;
        }

        .social {
            width: 48px;
            height: 48px;
            color: white;

            &:hover {
                color: white;
                background:var(--green);
            }
        }
    `}
`

const About = () => {
    const data = useStaticQuery(graphql`
        query {
            person1: file(relativePath: { eq: "team/team4.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            person2: file(relativePath: { eq: "team/team5.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            person3: file(relativePath: { eq: "team/team6.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            person4: file(relativePath: { eq: "team/team8.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            person5: file(relativePath: { eq: "team/team9.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            person6: file(relativePath: { eq: "team/team10.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            person7: file(relativePath: { eq: "team/team11.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            person8: file(relativePath: { eq: "team/team12.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            person9: file(relativePath: { eq: "team/team13.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            person10: file(relativePath: { eq: "team/team14.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <AboutDiv>
            <Row>
                <Col md={6} xs={12} className="left">
                    {/* <Reveal> */}
                    <Heading>
                        <div className="line">
                            <Tween to={{marginTop: '0'}} duration={.5}>
                            <span>ABOUT <b>NGO</b></span>
                            </Tween>
                        </div>
                    </Heading>
                    {/* </Reveal> */}

                    <div className="people mob">
                        <img src={Team} alt="person" className="" />
                    </div>

                    <Body className="max">
                        Next-Gen Operations (NGO) collaborates with local and multinational businesses in architecting and implementing new ways of working to drive operational excellence, global competitiveness and shared value. NGO seeks to unlock pragmatic pathways to leverage the business value of emerging technologies, future workforce, intelligent automation and data.
                    </Body>
                    <div className="contactDiv">
                        <a className="more" href="https://letsema.co.za/consulting/nextgenops/" target="_blank" rel="noreferrer">Learn More</a>
                        <div className="sociallinks">
                        <a className="social" href="mailto:nextgen.operations@letsema.co.za"><BsEnvelope /> </a>
                        <a className="social" href="https://www.linkedin.com/company/letsema-consulting-and-advisory/" target="_blank" rel="noreferrer"><RiLinkedinFill /> </a>
                        </div>
                    </div>
                </Col>
                <Col md={6} xs={12}>
                    <div className="people desk">
                        <div className="person two">
                        <Img
                            durationFadeIn={250}
                            objectFit="cover"
                            fluid={data.person1.childImageSharp.fluid}
                        />
                        </div>
                        <div className="person three">
                        <Img
                            durationFadeIn={250}
                            objectFit="cover"
                            fluid={data.person2.childImageSharp.fluid}
                        />
                        </div>
                        <div className="person four">
                        <Img
                            durationFadeIn={250}
                            objectFit="cover"
                            fluid={data.person3.childImageSharp.fluid}
                        />
                        </div>
                        <div className="person five">
                        <Img
                            durationFadeIn={250}
                            objectFit="cover"
                            fluid={data.person4.childImageSharp.fluid}
                        />
                        </div>
                        <div className="person six">
                        <Img
                            durationFadeIn={250}
                            objectFit="cover"
                            fluid={data.person5.childImageSharp.fluid}
                        />
                        </div>
                        <div className="person seven">
                        <Img
                            durationFadeIn={250}
                            objectFit="cover"
                            fluid={data.person6.childImageSharp.fluid}
                        />
                        </div>
                        <div className="person eight">
                        <Img
                            durationFadeIn={250}
                            objectFit="cover"
                            fluid={data.person7.childImageSharp.fluid}
                        />
                        </div>
                        <div className="person nine">
                        <Img
                            durationFadeIn={250}
                            objectFit="cover"
                            fluid={data.person8.childImageSharp.fluid}
                        />
                        </div>
                        <div className="person ten">
                        <Img
                            durationFadeIn={250}
                            objectFit="cover"
                            fluid={data.person9.childImageSharp.fluid}
                        />
                        </div>
                        <div className="person eleven">
                        <Img
                            durationFadeIn={250}
                            objectFit="cover"
                            fluid={data.person10.childImageSharp.fluid}
                        />
                        </div>
                    </div>
                </Col>
            </Row>
        </AboutDiv>
    )
}

export default About
