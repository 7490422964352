import * as React from "react"
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Body, GreenText, Heading, IntroHead, SubHeading } from "../styles"
import media from '../styles/media'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import { Reveal, Tween } from "react-gsap"

const MethoDiv = styled(Container)`
    .methimg {
        margin: 50px 0 80px;
    }

    .img-container {

    }

    ${media.laptop`
        .methimg {
            margin: 50px 0;
        }

        .total {
            margin: 50px 0 0;
        }

        .img-container {
            position: relative;

            .overlayImg {
                width: 100%;
                height: 100%;
                background: #0b0b0b;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    `}
`

const Methodology = () => {
    const data = useStaticQuery(graphql`
        query {
            methImg: file(relativePath: { eq: "meth.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <MethoDiv>
            <Row>
                <Col md={6} xs={12} className="left">
                    <IntroHead>Methodology</IntroHead>
                    <Body className="max">
                    Applying the same approach as our 2018 and 2019 surveys, interviews were conducted with experienced executives and senior management across various functions, including Supply Chain, Procurement, IT, Finance and Digital, with participating firms spanning a range of industries and sectors. Participants took part in the survey by answering a set of standardized and structured response questions, which were primarily quantitative in nature. A qualitative study, using semi-structured interviews as a means of data collection, was also performed.
                    </Body>
                    <Body className="max">
                    While it was ensured that various industries were explored, specific industry dynamics were not considered in this survey. Data confidentiality and privacy
                    requirements were treated with the utmost care, and all participants were asked to share their honest, ‘unvarnished’ opinions. All participants in the survey and their responses were evaluated from a South African operations perspective.
                    </Body>
                    <Body className="max">
                    Scope of study:
                    </Body>
                    <Body className="max">
                    23 interviews<br/>
                    20 unique companies<br/>
                    100% SA-based firms
                    </Body>
                </Col>
                <Col md={6} xs={12}>
                    <Body className="topSpace max">
                        This exploratory study followed a deductive approach, with data from survey responses utilised in raw form with no extraction of statistical inferences. Forward-looking extrapolation of survey results data did not form part of this study. Analysis of data from survey participant responses allowed further exploration and triangulation of findings, in combination with considering the insights from discussions with respondents.
                    </Body>

                    <Reveal>
                        <div className="img-container">
                            {/* <img src={Writing} /> */}
                            <Img
                                durationFadeIn={250}
                                objectFit="cover"
                                fluid={data.methImg.childImageSharp.fluid}
                                className="methimg"
                            />
                            <Tween to={{width: 0}} duration={2} delay={.5}>
                                <div className="overlayImg"></div>
                            </Tween>
                        </div>
                    </Reveal>
                </Col>
            </Row>
        </MethoDiv>
    )
}

export default Methodology
