import * as React from "react"
import styled from 'styled-components'
import { Container, Row } from 'react-bootstrap'
import { Heading } from "../styles"
import media from '../styles/media'

import Priority from "./Graphs/Priority"
import Journey from "./Graphs/Journey"
import CovidImpact from "./Graphs/CovidImpact"
import Value from "./Graphs/Value"
import { Reveal, Tween } from "react-gsap"

const SurveyResultsDiv = styled(Container)`

    ${media.laptop`
    `}
`

const SurveyResults = () => {

    return (
        <SurveyResultsDiv>
            <Row>
                {/* <Reveal> */}
                <Heading className="center">
                    <div className="line">
                        <Tween to={{marginTop: '0'}} duration={.5}>
                        <span><b>SURVEY RESULTS:</b></span>
                        </Tween>
                    </div>
                    <div className="line">
                        <Tween to={{marginTop: '0'}} duration={.5} delay={.4}>
                        <span className="desk">Digitalisation STRATEGY</span>
                        <span className="mob">Digitalisation</span>
                        </Tween>
                    </div>
                    <div className="line mob">
                        <Tween to={{marginTop: '0'}} duration={.5} delay={.8}>
                        <span className="mob">STRATEGY</span>
                        </Tween>
                    </div>
                </Heading>
                {/* </Reveal> */}
            </Row>
            <Priority />
            <Journey />
            <Value />
            <CovidImpact />
        </SurveyResultsDiv>
    )
}

export default SurveyResults
