import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { IntroHead, SubHeading, Body, InnerText } from "../styles"
import media from '../styles/media'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

// import { FullPage, Slide } from 'react-full-page'
import ReactPageScroller from 'react-page-scroller'

import TakeoutBg from '../images/takeoutbg.png'
import Header from "./header"

const TakeoutDiv = styled(Container)`
    height: 100vh;
    position:fixed;
    width:100vw;
    z-index: -1;
    padding: 100px var(--gutter-s) 0;
    position:fixed;
    top: 0;
    left: 0;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    overscroll-behavior: none;

    header {
        position: fixed;
        z-index: 9;
        top: 0;
        left: 0;
    }

    .hero-image {
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        position: absolute !important;
    }

    .leftspace {
        margin-left: 20px;
    }

    .scrollText {
        text-transform: uppercase;
        transform:rotate(270deg);
        position: absolute;
        left: 0px;
        margin-top: 55%;
        margin-left: 0;
    }

    .numberSlide {
        position:absolute;
        top: 120px;
        left: 20px;
        font-size: 18px;
        line-height: 24px;
    }

    ${media.tablet`
        padding: 150px 40px 0;

        .scrollText {
            text-transform: uppercase;
            transform:rotate(270deg);
            position: absolute;
            left: 45px;
            margin-top: 18%;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #717171;
        }

        .numberSlide {
            position:absolute;
            top: calc(30vh + 20px);
            left: 60px;
            font-size: 18px;
            line-height: 24px;
        }
        
        .leftspace {
            margin-left: 40px;
        }

        .extra {
            font-size: 18px;
            line-height: 24px;
        }

    `}
`

const Takeouts = ({ navigation, step }) => {
    const props = { navigation, step };
    const [slideNo, setSlideNo] = useState(0)

    const data = useStaticQuery(graphql`
        query {
            takeoutImg: file(relativePath: { eq: "takeoutbg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <>
        {/* <FullPage afterChange={e => setSlideNo(e.to)} duration={400}> */}
        <ReactPageScroller
          pageOnChange={(e) => setSlideNo(e)}
          containerHeight={window.innerHeight * 0.75}
        >
            <div className="takeoutdiv">
                <SubHeading className="keyTake">Digitalisation is a priority for all organisations included in the market study.</SubHeading>
                
            </div>

            <div className="takeoutdiv">
                <SubHeading className="keyTake">Digitalisation and digital transformation have accelerated in the last 5 years.</SubHeading>
                
            </div>

            <div className="takeoutdiv">
                <SubHeading className="keyTake">Participants consider cloud-based technologies as a major value generator, with 82% listing these in their top 3 value providers. This is a key driver in the migration of pre-existing systems, applications and services to cloud promoting ease of access and scalability. Advanced Analytics, Machine Learning &amp; AI (86%) were further noted as technologies that will provide the greatest value in future.</SubHeading>
                
            </div>

            <div className="takeoutdiv">
                <SubHeading className="keyTake">Value extraction from technology is situational. New technologies, outside of traditional ones associated with 4IR, are being invested in, with value creation from these technologies differing by industry.</SubHeading>
                
            </div>

            <div className="takeoutdiv">
                <SubHeading className="keyTake">Value extraction from technology is also strongly correlated to the level of resistance of employees to adoption and change. Low levels of resistance coupled with adequate change management present a positive foundation for value realisation.</SubHeading>
                
            </div>

            <div className="takeoutdiv">
                <SubHeading className="keyTake">Majority of participants consider COVID to have had a positive impact on progressing their firm’s digital strategy.</SubHeading>
                
            </div>

            <div className="takeoutdiv">
                <SubHeading className="keyTake">Skills and Competency, is the key barrier to unlocking digital transformation, followed by finance/budgets and culture/mindset transformation.</SubHeading>
                
            </div>

            <div className="takeoutdiv">
                <SubHeading className="keyTake">Data suggested that PMO driving transformation is key, closely followed by culture &amp; change management, a Dedicated, accountable senior leader and using an incubation model.</SubHeading>
                
            </div>

            <div className="takeoutdiv">
                <SubHeading className="keyTake">IT is seen as the most mature, followed closely by Finance and 'other'.  Interviews have indicated that companies are investing new technologies in the back office, are seeing high-levels of digitalisation.</SubHeading>
                
            </div>
        </ReactPageScroller>

        <TakeoutDiv>
            <Header {...props} />
            <Img
                durationFadeIn={250}
                objectFit="cover"
                fluid={data.takeoutImg.childImageSharp.fluid}
                className="hero-image"
            />

            <IntroHead className="leftspace">Key Takeouts</IntroHead>
            <p className="numberSlide">{slideNo + 1}/9</p>
            <p className="scrollText">Scroll</p>

        </TakeoutDiv>

        <div className={slideNo === 0 ? "extra active" : "extra inactive"}>
                    Digitalisation is a top 5 priority for 90% of organisations interviewed, and a Top 2 priority for 65% of survey respondents.
                </div>

                <div className={slideNo === 1 ? "extra active" : "extra inactive"}>
                    Over 80% of organisations began their digitalisation journey in the last 5 years, with over half of the respondents having starting within the last 3 years.
                </div>

                <div className={slideNo === 2 ? "extra active" : "extra inactive"}>
                    40% and 22% of respondents highlighted IoT and Blockchain respectively as technologies anticipated to provide the most value in the next 1 to 3 years. This finding represents a significant shift from previous studies.  Our conclusion is that businesses are investing in IoT to source real-time data from core operations (plants, manufacturing, supply chains) to improve visibility of operations, laying the foundation for AI / ML solutions moving forward.
                </div>

                <div className={slideNo === 3 ? "extra active" : "extra inactive"}>
                    Survey responses reveal that there is a proliferation of new technologies being explored, beyond those considered previously by organisations. Numerous respondents are pursuing and investing in technologies such as various open-source applications, robotic process automation, biometrics, smart contracts and partner collaboration / ecosystems enablement. 
                </div>

                <div className={slideNo === 4 ? "extra active" : "extra inactive"}>
                    While these technologies are mature in the market and new to these organisations, it is imperative that supporting initiatives are implemented to ensure successful adoption.
                </div>

                <div className={slideNo === 5 ? "extra active" : "extra inactive"}>
                    60% participants believe Covid had a positive impact on their firms digital strategy.
                </div>

                <div className={slideNo === 6 ? "extra active" : "extra inactive"}>
                Almost 50% of participants identified skills and competency availability as the top barrier to unlocking value from digital.
            </div>

                <div className={slideNo === 7 ? "extra active" : "extra inactive"}>
                25% of respondents ranked it as the top enabler.
                </div>

                <div className={slideNo === 8 ? "extra active" : "extra inactive"}>
                This indicates a focus on digitising core operational processes first which can serve as a foundation for more innovative digital initiatives.
                </div>
        </>

    )
}

export default Takeouts
