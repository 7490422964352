import * as React from "react"
import styled from 'styled-components'
import { Container, Row } from 'react-bootstrap'
import { Heading } from "../styles"
import media from '../styles/media'

import DigitalInitiatives from "./Graphs/DigitalInitiatives"
import JourneyFar from "./Graphs/JourneyFar"
import Barriers from "./Graphs/Barriers"
import Capabilities from "./Graphs/Capabilities"
import Functions from "./Graphs/Functions"
import { Reveal, Tween } from "react-gsap"

const SurveyResults2Div = styled(Container)`

    ${media.laptop`
    `}
`

const SurveyResults2 = () => {

    return (
        <SurveyResults2Div>
            <Row>
                {/* <Reveal> */}
                <Heading className="center">
                    <div className="line">
                        <Tween to={{marginTop: '0'}} duration={.5}>
                        <span><b>SURVEY RESULTS:</b></span>
                        </Tween>
                    </div>
                    <div className="line">
                        <Tween to={{marginTop: '0'}} duration={.5} delay={.4}>
                        <span className="desk"><b> Implementation of</b></span>
                        <span className="mob"><b> Implementation</b></span>
                        </Tween>
                    </div>
                    <div className="line">
                        <Tween to={{marginTop: '0'}} duration={.5} delay={.8}>
                        <span className="desk">DIGITAL STRATEGY</span>
                        <span className="mob">of DIGITAL</span>
                        </Tween>
                    </div>
                    <div className="line mob">
                        <Tween to={{marginTop: '0'}} duration={.5} delay={1.2}>
                        <span className="mob">STRATEGY</span>
                        </Tween>
                    </div>
                </Heading>
                {/* </Reveal> */}
            </Row>
            <JourneyFar />
            <Barriers />
            <Capabilities />
            <DigitalInitiatives />
            <Functions />
        </SurveyResults2Div>
    )
}

export default SurveyResults2
