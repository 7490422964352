import * as React from "react"
import { Modal, Row } from 'react-bootstrap'
import { FormDescript, FormHeading, InfoModal, LegendDiv } from "../../styles"

import { BsPlus } from 'react-icons/bs'
import styled from "styled-components";
import { Reveal, Tween } from 'react-gsap'

import Cart from '../../images/icons/cart.svg'
import Comp from '../../images/icons/comp.svg'
import Chart from '../../images/icons/chart.svg'
import Help from '../../images/icons/help.svg'
import Calc from '../../images/icons/calc.svg'
import Plus from '../../images/icons/plus.svg'
import Dots from '../../images/icons/dots.svg'
import media from "../../styles/media";

const BarDiv = styled.div`
  .chart {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(100, 1fr) 1fr;
    grid-row-gap: 50px;
    height: 645px;
    width: 85vw;
    padding: 5px 10px;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-column-start: 1;
    grid-column-end: 101;
    display: flex;
    width:70vw;
    
    .label{
      position: absolute;
      margin-top: -28px;
      margin-bottom: 0;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #EAEAEA;
      display: flex;
      align-items: center;

      img{
        height: 22px;
        margin-bottom: 0;
      }
    }

    .bar-1 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 83%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-1b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 8%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-1c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 15%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width:71%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-2b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 27%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-2c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 8%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 89%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-3b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 9%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-3c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 9%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-4 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 69%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-4b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 15%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-4c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 22%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-5 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 77%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-5b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 10%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-5c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 19%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-6 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 52%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-6b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 40%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-6c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 15%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

    .bar-7 {
      align-self:flex-end;
      border-radius: 10px;
      height: 100%;
      width: 83%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
      z-index:9;
    }
    .bar-7b {
      margin-left: -2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width:19%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-7c {
      margin-left:-2%;
      align-self:flex-end;
      border-radius: 0 10px 10px 0;
      height: 100%;
      width: 0%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
      z-index:-1;
    }

  }

  ${media.laptop`
  .chart {
    margin-left: -5vw;
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(100, 1fr) 1fr;
    grid-row-gap: 50px;
    height: 725px;
    width: 70vw;
    padding: 5px 10px;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-column-start: 1;
    grid-column-end: 101;
    display: flex;
    width:70vw;
    
    .label{
      position: absolute;
      margin-top: -28px;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #EAEAEA;
      display: flex;
      align-items: center;

      img{
        height: 22px;
        margin-bottom: 0;
      }
    }

  }
  `}
`

const Functions = () => {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return (
      <>
      <Row className="centered">
        <div className="formHead">
        <div className="module-border-wrap large">
        <FormHeading>
          Which function in your organization is most mature with regards to digitalization
          <button onClick={() => setShow(true)}><BsPlus /></button>
        </FormHeading>
        </div>
        <FormDescript>Back-office functions are being digitised through deployment of targeted and / or large technology solutions, with IT, Finance, HR and Procurement &amp; Supply Chain leading. The data also indicates that core operations were potentially receiving strong funding to drive digitalisation, such as manufacturing and operations.</FormDescript>
        </div>
        <Reveal >
        <div className="graphCon">
          <BarDiv>
            <div className="chart">
              <div className="bar">
                <div className="label"><img src={Calc}alt="cloud" /> &nbsp; Finance</div>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-1"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-1b"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-1c"></div>
                </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">18%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Help}alt="cloud" /> &nbsp; Human Resources</div>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-2"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-2b"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-2c"></div>
                </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">18%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Comp}alt="cloud" /> &nbsp; IT</div>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-3"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-3b"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-3c"></div>
                </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">17%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Cart}alt="cloud" /> &nbsp; Procurement &amp; Supply Chain</div>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-4"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-4b"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-4c"></div>
                </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">17%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Chart}alt="cloud" /> &nbsp; Sales &amp; Marketing</div>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-5"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-5b"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-5c"></div>
                </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">14%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Plus}alt="cloud" /> &nbsp; Health &amp; Safety</div>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-6"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-6b"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-6c"></div>
                </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">9%</div>
                </div>
              </LegendDiv>

              <div className="bar">
                <div className="label"><img src={Dots}alt="cloud" /> &nbsp; Other</div>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-7"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-7b"></div>
                </Tween>
                <Tween from={{ width: 0 }} duration={1} delay={0}> 
                <div className="bar-7c"></div>
                </Tween>
              </div>
              <LegendDiv className="spaceLeft">
                <div className="zero">
                  <div className="colourBlock zero">7%</div>
                </div>
              </LegendDiv>

            </div>
          </BarDiv>

          <LegendDiv className="four">
            <div className="zero">
              <div className="colourBlock zero">x%</div>
              <p className="legText">Percentage of total responses</p>
            </div>
            <div className="first">
              <div className="colourBlock one"></div>
              <p className="legText">First Choice</p>
            </div>
            <div className="second">
              <div className="colourBlock two"></div>
              <p className="legText">Second Choice</p>
            </div>
            <div className="third">
              <div className="colourBlock three"></div>
              <p className="legText">Third Choice</p>
            </div>
          </LegendDiv>
        </div>
        </Reveal>
      </Row>

      <InfoModal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <p className="infoModalTop">
          Companies are investing in new technologies in the back office, with functions such as <span>Finance, HR, IT and Procurement &amp; Supply Chain</span> considered most mature
          </p>
          <ul className="infoModalList">
            <li>
            In line with expectations, the survey shows that companies are prioritising traditional and core business functions as their first targets for digitalisation.  We observe that these core functions (e.g. finance, IT etc) stand out as the most digitally mature functions in most organisations. Below we look at selected examples to support this observation.
            </li>
            <li>
            The Head of Digital Channels at one of South Africa’s largest retail <span>banks identified IT, HR, Procurement and Finance</span> as having the most advanced digital maturity in the organisation. In the recent years, the bank rolled out Workday, a financial &amp; human capital management software, to further streamline and digitise human resources processes.
            </li>
            <li>
            The group CIO of a large <span>petrochemical</span> organisation believes <span>Procurement &amp; Supply Chain, IT, Finance</span> capabilities are digitally quite mature. A significant technology investment in Finance has seen great improvement in terms of digital maturity. To date, there are visible incremental improvements in Procurement &amp; Supply Chain as capabilities in reporting and generating insights out of the existing data have been uplifted.
            </li>
            <li>
            Perspectives from a <span>supply chain traceability solutions</span> provider revealed interesting insights. While their IT function is one of the most mature capabilities, <span>Sales and Marketing</span> function is amongst the top two advanced functions, ahead of Finance and HR functions. This nuance in comparison to other industries is dictated, mostly likely, by the nature of this business and the importance of Sales and Marketing as a key business enabler.
            </li>
            <li>
            In <span>healthcare</span> a similar trend was observed, where the traditional core functions like <span>HR and Finance</span> are prioritised for digitalisation. 
            </li>
          </ul>
        </Modal.Body>
      </InfoModal>
      </>
    )
}

export default Functions