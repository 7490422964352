import React, { useState } from "react"
import { Row } from 'react-bootstrap'
import { FormDescript, FormHeading, LargeNo, LargeInfo, LargeUnder, SideText } from "../../styles"

import { PieChart, Pie, Cell, Sector } from 'recharts';
import { Reveal, Tween } from 'react-gsap'

const Priority = () => {
  const [selected, setSelected] = useState(null)

  const dataMob = [
    { name: 'Yes, in the top 2 priorities', value: 65, color:'#61AC4A' },
    { name: 'Yes, between priorities 3-5', value: 22, color:'#1A6968' },
    { name: 'Yes, but outside the top 5 organisations priorities', value: 13, color:'#303333' }
  ];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    setSelected(payload.name)

    return (
      <g>
        <text x={cx} y={260} dy={8} textAnchor="middle" fill={'#eaeaea'} fontSize={'48px'}>
          {`${payload.value}%`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={payload.color}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius - 1}
          outerRadius={outerRadius + 20}
          fill={payload.color}
        />
        {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={payload.color} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} fontSize={`16px`} y={ey} textAnchor={textAnchor} fill={'white'}>{payload.name} {payload.name2b}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} fontSize={`16px`} y={ey + 21} textAnchor={textAnchor} fill={'white'}>{payload.name2} {payload.name3b} {payload.name4b}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} fontSize={`16px`} y={ey + 42} textAnchor={textAnchor} fill={'white'}>{payload.name3} {payload.name5b}</text> */}
      </g>
    );
  };  

  const renderActiveShapeMob = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    setSelected(payload.name)
  
    return (
      <g>
        <text x={cx} y={160} dy={8} textAnchor="middle" fill={'#eaeaea'} fontSize={'32px'}>
          {`${payload.value}%`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={payload.color}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius - 1}
          outerRadius={outerRadius + 20}
          fill={payload.color}
        />
        {/* <text fontSize={`16px`} width={400} y={380} fill={'white'}>{payload.name} {payload.name2} {payload.name2} {payload.name2b} {payload.name3} {payload.name3b} {payload.name4b} {payload.name5b}</text> */}
      </g>
    );
  };  

  const [activeState,setActiveState] = React.useState(0)


  const onPieEnter = (_, index) => {
    setActiveState(index)
  };
  
  var colors = ['#61AC4A','#1A6968','#303333']

    return (
      <Row className="centered">
      <div className="formHead">
          <div className="module-border-wrap">
          <FormHeading>Is digitalisation a priority for your organisation?</FormHeading>
          </div>
          <FormDescript>Digitalisation is considered a priority for all organisations included in the market study, with almost 90% of respondents saying it was within the top 5 priorities for their organisation. For over two thirds of participants, digitalisation is within the top two priorities for their organisation.</FormDescript>
          </div>

          <SideText className="top">SELECT A PIECE OF THE PIE</SideText>

          <Reveal repeat>
          <div className="graphCon desk">
            <PieChart width={900} height={500}>
              <Pie
                activeIndex={activeState}
                activeShape={renderActiveShape}
                data={dataMob}
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={150}
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                {
                  dataMob.map((entry, index) => (
                    <Cell key={`cell-${index}`} stroke={'none'} fill={colors[index]}/>
                  ))
                }
              </Pie>
            </PieChart>

            <p className="pieBottom">{selected}</p>
          </div>
          <div className="graphCon mob">
            <PieChart width={380} height={380}>
              <Pie
                activeIndex={activeState}
                activeShape={renderActiveShapeMob}
                data={dataMob}
                cx="50%"
                cy="40%"
                innerRadius={60}
                outerRadius={120}
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                {
                  dataMob.map((entry, index) => (
                    <Cell key={`cell-${index}`} stroke={'none'} fill={colors[index]}/>
                  ))
                }
              </Pie>
            </PieChart>

            <p className="pieBottom">{selected}</p>
          </div>
          </Reveal>
            
          <LargeUnder>
          <LargeNo>90%</LargeNo>
          <LargeInfo>of respondents had digitalization as part<br/> of their top 5 organisational priorities</LargeInfo>
          </LargeUnder>
      </Row>
    )
}

export default Priority
