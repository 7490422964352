import * as React from "react"
import styled from 'styled-components'
import { Row } from 'react-bootstrap'
import { FormDescript, FormHeading } from "../../styles"

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/navigation"
import "swiper/css/effect-fade"

import Left from '../../images/left.png'
import Right from '../../images/right.png'
import LeftHov from '../../images/LeftHov.png'
import RightHov from '../../images/RightHov.png'
import Circle from '../../images/circle.png'

import ManFill from '../../images/man-fill.png'
import ManOutline from '../../images/man-outline.png'

import media from "../../styles/media"

import SwiperCore, {
  Navigation, EffectFade
} from 'swiper';

const UserDiv = styled.div`
  margin: 50px 0 0 !important;

  .userBlocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .info {
    .circleImg{
      width: 85%;
      position: absolute;
      display:none;
      z-index: 2;
    }

    .swiper {
      height: 150px;
      padding: 0px;

      .swiper-button-prev, .swiper-button-next {
        width: 30px;
        height: 30px;
        color: transparent;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: -12px;
      }


      .swiper-button-prev {
        background-image: url(${Left}) !important;
      }

      .swiper-button-next {
        background-image: url(${Right}) !important;
      }


      .swiper-slide{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #EAEAEA;
        background: #0b0b0b;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;

        span {
          font-family: 'Segoe Semi';
          text-align: center;
        }

        p {
          margin:0;
        }
      }
    }
  }

  .userImg {
    width: 80%;
    height: fit-content;
    margin: 0 10% 50px;
  }

  ${media.tablet`
    margin: 150px 0 0 !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5vw;
    justify-content: center;

    .info {
      width: 30vw;

      /* .circleImg{
        position: absolute;
        display:block;
        width: 28vw;
        margin: 30px 6.25vw;
      } */

      .swiper {
        height: 100%;
        width:40vw;
        /* padding: 50px; */

        .swiper-button-prev, .swiper-button-next {
          width: 60px;
          height: 60px;
          color: transparent;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          margin-top: -20px;
          transition: all .3s linear;
        }

        .swiper-button-prev {
          &:hover {
            background-image: url(${LeftHov}) !important;
          }
        }

        .swiper-button-next {
          &:hover {
            background-image: url(${RightHov}) !important;
          }
        }

        .swiper-slide{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          /* background: url(${Circle}); */
          /* background: transparent url(${Circle}) center/ contain no-repeat; */
          background: #0b0b0b url(${Circle}) center/68% auto no-repeat;

          span {
            font-size: 64px;
            line-height: 56px;
            text-align: center;
          color: #EAEAEA;
            margin-bottom: 20px;
            margin-top: 15px;
          }

          font-size: 24px;
          line-height: 24px;
          text-align: center;
          color: #EAEAEA;
          text-transform: uppercase;
        }
      }
    }

    .userBlocks {
      max-width: 35vw;
      height: fit-content;
      margin: 0;
      place-self:center;
      min-height:40vw;

      img {
        height: 55px;
        width: 50px;
      }
    }
  `}
`

const Roles = () => {
  SwiperCore.use([Navigation, EffectFade]);

  const [activeSlide, setActiveSlide] = React.useState(0)

  console.log(activeSlide);

  return (
    <Row className="centered">
    <div className="formHead">
      <div className="module-border-wrap">
      <FormHeading>User roles of survey participants</FormHeading>
      </div>
      <FormDescript>Almost two thirds of participants are either executives or in senior leadership. At this level, the participants are well positioned to understand their firm’s digital strategy and long-term horizon for digital transformation.</FormDescript>
      </div>
      <UserDiv>
          <div className="userBlocks">
            {activeSlide === 0 && (
              <>
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
              </>
            )}
            {activeSlide === 1 && (
              <>
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
              </>
            )}
            {activeSlide === 2 && (
              <>
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
              </>
            )}
            {activeSlide === 3 && (
              <>
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
              </>
            )}
            {activeSlide === 4 && (
              <>
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
              </>
            )}
            {activeSlide === 5 && (
              <>
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
              </>
            )}
            {activeSlide === 6 && (
              <>
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManFill} className="fill" alt="man fill" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
                <img src={ManOutline} className="outline" alt="man outline" />
              </>
            )}
          </div>
        <div className="info">
          {/* <img src={Circle} className="circleImg" alt="circle" /> */}

          <Swiper
            loop={true}
            spaceBetween={0}
            effect={'fade'}
            slidesPerView={1}
            navigation
            onSlideChange={e => setActiveSlide(e.activeIndex)}
          >
            <SwiperSlide>
              <span>43%&nbsp;</span>
              <p>Executive</p>
            </SwiperSlide>
            <SwiperSlide>
              <span>17%&nbsp;</span>
              <p>Senior Leadership</p>
            </SwiperSlide>
            <SwiperSlide>
              <span>26%&nbsp;</span>
              <p>Senior Management</p>
            </SwiperSlide>
            <SwiperSlide>
              <span>9%&nbsp;</span>
              <p>Management</p>
            </SwiperSlide>
            <SwiperSlide>
              <span>4%&nbsp;</span>
              <p>Operational</p>
            </SwiperSlide>
          </Swiper>
        </div>
      </UserDiv>
    </Row>
  )
}

export default Roles