import * as React from "react"
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Body, GreenText, Heading, IntroHead, SubHeading } from "../styles"
import media from '../styles/media'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import { Reveal, Tween } from 'react-gsap'

const DigEcoDiv = styled(Container)`

    .circle {
        margin-bottom: 50px;
    }

    .subspace {
        margin: 50px 0;
        padding-left: 0;
    }

    ${media.laptop`
        .circle {
            /* float: right; */
            width: 50%;
            margin-bottom: 130px;
        }

        .subspace {
            margin: 0;
        }

        .spaced {
            margin-top: 62px;
        }
    `}
`

const DigitalEconomy = () => {

    const data = useStaticQuery(graphql`
        query {
            circeImg: file(relativePath: { eq: "circleimg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <DigEcoDiv>
            <Row>
                <Col md={6} xs={12} className="left">
                    {/* <Reveal> */}
                        <IntroHead>Introduction</IntroHead>
                        <Heading>
                            <div className="line">
                                <Tween to={{marginTop: '0'}} duration={.5}>
                                <span><b>The state of</b></span>
                                </Tween>
                            </div>
                            <div className="line">
                                <Tween to={{marginTop: '0'}} duration={.5} delay={.4}>
                                <span className="desk"><b>innovation and the</b></span>
                                <span className='mob'><b>innovation and</b></span>
                                </Tween>
                            </div>
                            <div className="line">
                                <Tween to={{marginTop: '0'}} duration={.5} delay={.8}>
                                <span className="desk">digital economy</span>
                                <span className="mob">the digital</span>
                                </Tween>
                            </div>
                            <div className="line mob">
                                <Tween to={{marginTop: '0'}} duration={.5} delay={1.2}>
                                <span className="mob">economy</span>
                                </Tween>
                            </div>
                        </Heading>
                    {/* </Reveal> */}

                    {/* <img className="circle" src={CircleImg} alt="robot hand" /> */}
                    <Img
                        durationFadeIn={250}
                        objectFit="cover"
                        fluid={data.circeImg.childImageSharp.fluid}
                        className="circle"
                    />
                </Col>
                <Col md={6} xs={12} className="right spaced">
                    <Body className="max">
                        A key aspiration of Letsema’s Next-Gen Operations practice is to collaborate with businesses in architecting and implementing new ways of work to drive operational excellence, global competitiveness and shared value. We consider digital transformation as a key enabler for businesses to achieve this.
                    </Body>
                    <Body className="max">
                        The Covid-19 pandemic resulted in significant shifts in traditional business practice and operations. Companies were forced to rely on digital tools to ensure business continuity and in many cases, business survival. 
                    </Body>
                    <Body className="max">
                        Previous market studies conducted by Next-Gen Operations focused on digital transformation efforts within the procurement and supply chain space. However, the impact of Covid-19 has been far-reaching, beyond certain functions within a business.
                    </Body>
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12} className="left subspace">
                    <SubHeading>
                        Within this context, the 2021 market study set out to understand how South African operations executives are designing and implementing their digital strategies to realise business value and drive competitiveness. 
                    </SubHeading>
                </Col>
                <Col md={6} xs={12} className="right">
                    <GreenText>key objectives of this study</GreenText>
                    <Body className="lined">Key barriers to unlocking value from digital </Body>
                    <Body className="lined">Key capabilities that support the digital transformation ambitions </Body>
                    <Body className="lined">Major focus areas of digital transformation</Body>
                    <Body className="lined">The approaches being taken to achieve digital transformation</Body>
                    <Body className="lined">The impact of the Covid-19 pandemic on the digitalisation agenda</Body>
                </Col>
            </Row>
        </DigEcoDiv>
    )
}

export default DigitalEconomy
