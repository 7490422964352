import * as React from "react"
import styled from 'styled-components'
import { Row } from 'react-bootstrap'
import { FormDescript, FormHeading } from "../../styles"
import media from "../../styles/media"

import { Reveal, Tween } from 'react-gsap'

const BarDiv =styled.div`
  .chart {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(100, 1fr);
    grid-column-gap: 20px;
    height: 250px;
    width: 60vw;
    padding: 5px 10px;
    position: relative;
  }

  .bar {
    border-radius: 10px;
    background-color: rgba(48,51,51,.2);
    grid-row-start: 1;
    grid-row-end: 101;
    display: flex;
    justify-content: center;

    .label{
      position:absolute;
      text-align: center;
      align-self: flex-start;
      margin-top: 25px;
    }

    .itemName {
      position: absolute;
      text-align: center;
      align-self: flex-end;
      /* font-family: 'Segoe Semi'; */
      max-width: 10vw;
      color: #EAEAEA;
      text-transform: uppercase;
      font-size: 2vw;
      line-height: 17px;
      height: 40px;
      bottom: -45px;
    }

    .bar-1 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 57%;
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    .bar-2 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 26%;
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    .bar-3 {
      align-self:flex-end;
      border-radius: 10px;
      width: 100%;
      height: 17%;
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
    }
  }

  ${media.tablet`
    .chart {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(100, 1fr);
      grid-column-gap: 20px;
      height: 525px;
      width: 40vw;
      padding: 5px 10px;
    }

    .bar {
      .itemName {
        font-size: 1.2vw;
        line-height: 1.25;
      }
    }
  `}

`

const Journey = () => {
    return (
      <Row className="centered">
      <div className="formHead">
        <div className="module-border-wrap">
        <FormHeading>When did you start your digitalisation journey?</FormHeading>
        </div>
        <FormDescript>80% of participating organisations started their digitalisation journey in the last five years, with under 60% starting in the last 3 years.</FormDescript>
        </div>
        <Reveal>
          <div className="graphCon">
            <BarDiv>
              <div className="chart">
                <div className="bar">
                  <div className="label">57%</div>
                  <Tween from={{ height: 0 }} duration={1}>     
                  <div className="bar-1"></div>
                  </Tween>
                  <div className="itemName">1-3 years</div>
                </div>
                <div className="bar">
                  <div className="label">26%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={0.3}>     
                  <div className="bar-2"></div>
                  </Tween>
                  <div className="itemName">3 to 5 years</div>
                </div>
                <div className="bar">
                  <div className="label">17%</div>
                  <Tween from={{ height: 0 }} duration={1} delay={0.6}>     
                  <div className="bar-3"></div>
                  </Tween>
                  <div className="itemName">Older than 5 years</div>
                </div>
              </div>
            </BarDiv>
          </div>
        </Reveal>
      </Row>
    )
}

export default Journey