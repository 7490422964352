import * as React from "react"
import styled from 'styled-components'
import { Container, Row } from 'react-bootstrap'
import { FormDescript, FormHeading, Heading } from "../styles"
import media from '../styles/media'

import { Bar, Scatter } from 'react-chartjs-2'
import BusinessGraph from "./Graphs/BusinessGraph"
import Composition from "./Graphs/Composition"
import Roles from "./Graphs/Roles"
import { Reveal, Tween } from "react-gsap"

const DemoBreakdownDiv = styled(Container)`

    ${media.laptop`
    `}
`

const DemoBreakdown = () => {

    return (
        <DemoBreakdownDiv>
            <Row>
                {/* <Reveal> */}
                <Heading className="center">
                    <div className="line">
                        <Tween to={{marginTop: '0'}} duration={.5}>
                        <span className="desk"><b>Demographic Breakdown</b> </span>
                        <span className="mob"><b>Demographic </b> </span>
                        </Tween>
                    </div>
                    <div className="line">
                        <Tween to={{marginTop: '0'}} duration={.5} delay={.4}>
                        <span className="desk">of Participants</span>
                        <span className="mob">Breakdown</span>
                        </Tween>
                    </div>
                    <div className="line mob">
                        <Tween to={{marginTop: '0'}} duration={.5} delay={.8}>
                        <span className="mob">of Participants</span>
                        </Tween>
                    </div>
                </Heading>
                {/* </Reveal> */}
            </Row>
            <Composition />
            <BusinessGraph />
            <Roles />
        </DemoBreakdownDiv>
    )
}

export default DemoBreakdown
