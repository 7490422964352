import * as React from "react"
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Body, Heading, IntroHead } from "../styles"
import media from '../styles/media'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import { Reveal, Tween } from "react-gsap"

const ConclusionDiv = styled(Container)`

    img {
        margin: 50px 0 80px;
        min-height: 180px;
    }
    
    .imgText {
        position: absolute;
        display: flex;
        margin: 60px 5vw;
        width: 80vw;
        text-align: center;
    }

    .topImg {
        margin: 0 0 70px;
    }

    .bottom {
        min-height: 250px;
    }

    ${media.laptop`
        .middle {
            margin: 100px 0;
        }

        .bottom {
            margin: 100px 0 0;
        }

        img {
            float: right;
            width: 100%;
            margin: 50px 0;
        }

        .largeImg {
            height: 400px;
            object-fit: cover;
        }

        .imgText {
            position: absolute;
            display: flex;
            margin: 170px 15vw;
            width: 60vw;
        }


        .img-container {
            position: relative;

            img {
                height: 300px;
            }

            .overlayImg {
                width: 100%;
                height: 400px;
                background: #0b0b0b;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .topImg {
            margin: 0px;
        }
    `}
`

const Conclusion = () => {
    const data = useStaticQuery(graphql`
        query {
            concImg: file(relativePath: { eq: "conc.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            conc2Img: file(relativePath: { eq: "conc2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <ConclusionDiv>
            <Row>
                <Col md={6} xs={12} className="left">
                    <IntroHead>CONCLUSION</IntroHead>
                    {/* <Reveal> */}
                    <Heading>
                        <div className="line">
                            <Tween to={{marginTop: '0'}} duration={.5}>
                            <span><b>Increased</b></span>
                            </Tween>
                        </div>
                        <div className="line">
                            <Tween to={{marginTop: '0'}} duration={.5} delay={.4}>
                            <span><b>prioritisation of</b></span>
                            </Tween>
                        </div>
                        <div className="line">
                            <Tween to={{marginTop: '0'}} duration={.5} delay={.8}>
                            <span>digital strategies</span>
                            </Tween>
                        </div>
                    </Heading>
                    {/* </Reveal> */}
                </Col>
                <Col md={6} xs={12} className="topSpaceLg right">
                    <Body className="max">
                    As the survey results show, increased prioritisation of digital strategies in has resulted in the acceleration of digitalisation and digital transformation initiatives over the past five years. For many of the respondents, digital strategies are no longer a ‘nice to have’ or limited to digitally-inclined industries. Instead, they form part of broader organisational strategies which aim to keep their firm relevant, resilient and sustainable for the future.
                    </Body>
                    <Body className="max">
                    Digital acceleration has also been influenced by the global pandemic, with majority of participants finding that Covid to have had a positive impact on their firm’s digital strategy. Overall, respondents felt the pandemic and resultant consequences increased executive understanding of digital and the role it plays in supporting business continuity, building resilience and highlighting untapped opportunities.
                    </Body>
                </Col>
            </Row>
            <Row className="middle">
                <Col md={6} xs={12} className="left">
                    <Body className="large">
                    Participants consider cloud-based technologies as a major value generator, with 82% listing these in their top 3 value providers. This is a key driver in the migration of pre-existing systems, applications and services to cloud promoting ease of access and scalability. Advanced Analytics, Machine Learning &amp; AI (86%) were further noted as technologies that will provide the greatest value in future.
                    </Body>
                </Col>
                <Col md={6} xs={12}  className="right">
                    {/* <img className="writing" src={Conc} alt="woman writing" /> */}
                    <Reveal>
                        <div className="img-container topImg">
                            {/* <img src={Conc} /> */}
                            <Img
                                durationFadeIn={250}
                                objectFit="cover"
                                fluid={data.concImg.childImageSharp.fluid}
                                className="concimg"
                            />
                            <Tween to={{width: 0}} duration={2} delay={1}>
                                <div className="overlayImg"></div>
                            </Tween>
                        </div>
                    </Reveal>
                </Col>
            </Row>
            <Row>
                <Col md={5} xs={12} className="left">
                    <Body className="max">
                    However, despite increased understanding and buy-in to digital strategies, key barriers persist in realizing their full potential. The survey highlighted the importance of people in deriving value from digital. For almost half of respondents, the availability of requisite skills and competencies is the key barrier to unlocking digital transformation. Skills shortages were identified as a critical barrier in previous market studies undertaken by NGO, with no change in sentiment.  
                    </Body>
                </Col>
                <Col md={7} xs={12} className="right">
                    <Body className="max">
                    Business leaders need to assess their options to ensure they are adequately prepared for what we know is rapidly changing future.A key finding was that those organisations close to the end of their digital implementation journeys all had one thing in common: they prioritised strategic partnerships/ecosystems as a key enabler. Understanding where and how to leverage partnerships and a broader ecosystem can be a game changer for the implementation of digital strategies. 
                    </Body>
                </Col>
            </Row>
            <Row className="bottom">
                {/* <img className="writing largeImg" src={Conc2} alt="woman writing" /> */}
                <Img
                    durationFadeIn={250}
                    objectFit="cover"
                    fluid={data.conc2Img.childImageSharp.fluid}
                    className="largeImg"
                />
                <Body className="large imgText">As the pandemic continues to disrupt business as usual, the extent to which organisations successfully leverage technology will likely determine the extent to which firms are able to remain resilient and future-ready.</Body>
            </Row>
        </ConclusionDiv>
    )
}

export default Conclusion
