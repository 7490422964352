import * as React from "react"
import { Modal, Row } from 'react-bootstrap'
import { FormDescript, FormHeading, InfoModal, SideText } from "../../styles"

import { BsPlus } from 'react-icons/bs'

import { PieChart, Pie, Cell, Sector } from 'recharts';
import { Reveal, Tween } from 'react-gsap'

const CovidImpact = () => {
  const [show, setShow] = React.useState(false);
  const [selected, setSelected] = React.useState(null)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const data = [
    { name: 'POSITIVE', value: 60, color:'#61AC4A' },
    { name: 'NEGATIVE', value: 40, color:'#303333' }
  ];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    setSelected(payload.name)
  
    return (
      <g>
      <text x={cx} y={cy + 20} dy={8} textAnchor="middle" fill={'#eaeaea'} fontSize={'48px'}>
        {`${payload.value}%`}
      </text>
        {/* <text x={cx} y={cy + 28} dy={8} textAnchor="middle" fill={'#767676'} fontSize={20} textTransform={'uppercase'}>
          {`${payload.name}`}
        </text> */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={payload.color}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius - 1}
          outerRadius={outerRadius + 20}
          fill={payload.color}
        />
        {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={payload.color} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
        {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} fontSize={`16px`} y={ey} textAnchor={textAnchor} fill={'white'}>{payload.name}</text> */}
      </g>
    );
  };  
 

  const [activeState,setActiveState] = React.useState(0)


  const onPieEnter = (_, index) => {
    setActiveState(index)
  };
  
  var colors = ['#61AC4A','#303333']
  
    return (
      <>
      <Row className="centered">
        <div className="formHead">
        <div className="module-border-wrap large">
        <FormHeading>
          What has the impact of Covid been on your firms digitalisation strategy?
          <button onClick={() => setShow(true)}><BsPlus /></button>
        </FormHeading>
        </div>
        <FormDescript>For 60% of participants, Covid had a positive impact on their firm’s digitalisation strategy.</FormDescript>
        </div>

        <SideText>SELECT A PIECE OF THE PIE</SideText>

        <Reveal repeat>
        <div className="graphCon desk">
            <PieChart width={600} height={400}>
              <Pie
                activeIndex={activeState}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={100}
                outerRadius={170}
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                {
                  data.map((entry, index) => (
                    <Cell key={`cell-${index}`} stroke={'none'} fill={colors[index]}/>
                  ))
                }
              </Pie>
            </PieChart>
            <br/><br/>
            <p className="pieBottom">{selected}</p>
        </div>
        <div className="graphCon mob">
            <PieChart width={370} height={400}>
              <Pie
                activeIndex={activeState}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={130}
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                {
                  data.map((entry, index) => (
                    <Cell key={`cell-${index}`} stroke={'none'} fill={colors[index]}/>
                  ))
                }
              </Pie>
            </PieChart>
            <br/><br/>
            <p className="pieBottom">{selected}</p>
        </div>
        </Reveal>
      </Row>

      <InfoModal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <p className="infoModalTop">
          The global pandemic has <span>accelerated the need for digital</span>, further supported by <span>60% of survey</span> participants viewing COVID-19 as having a <span>positive impact</span> on their organisation’s digital journey.
          </p>
          <ul className="infoModalList">
            <li>
            According to a senior strategic project's leader at one of the leading <span>courier services</span> company, COVID19 had a positive impact on their digitalisation approach and journey. Having placed technology at the centre of their business model, COVID19 <span>trengthened their business continuity</span> capabilities and established a means to achieve flexible working conditions for the courier.
            </li>
            <li>
            An operations manager in the <span>chemicals</span> space observes that the pandemic acted as a catalyst to <span>fast-track</span> digitalisation initiatives within the company. As the pandemic progresses, the business has been proactive in developing digital solutions and fully utilising existing digital platforms such as Microsoft Power BI and Power Apps. 
            </li>
            <li>
            While digital transformation has been a priority at one of the big <span>banks</span>, the visible impact of COVID19 on operations has simplified communication with board and exco members who did not have a full appreciation of what digital transformation entails. Consequently, the need to change has been crystalised as there is now <span>more attention</span> on what digital transformation means. In addition, the pandemic has “lit the fire” pushing the bank to increase the availability of its products on virtual channels to alleviate pressure on the branch network.
            </li>
            <li>
            The CEO of a <span>fintech and big data</span> company (which offers supply chain traceability solution) says that the pandemic has provided clearer insight into what opportunities are available e.g. food security was enabled using traceability. Generally, there was <span>increased interest</span> in the traceability technology. As clients understood the technology, acceptance and participation has increased as they commence their digital journeys. 
            </li>
            <li>
            In the <span>telecoms</span> industry, the increased demand for data products (fibre, LTE), software, and cloud solutions promoted fully managed services to clients as observed by the managed services executive of a large telco. Voice usage dropped significantly as  demand shifted to data products. In response, the company leveraged existing technologies and partnerships to enable clients to transition to working-from-home workforce. In addition, there was a need to design new processes around the client and their workforce. 
            </li>
            <li>
            Perspectives from a senior education leader in a <span>provincial government</span> highlight how the department was pushed to adopt virtual working. As a result of the pandemic, infrastructure and connectivity became priorities together with virtual channels training to facilitate digital ways of working.
            </li>
            <li>
            A <span>heavy engineering company</span> fast tracked digital projects as the pandemic progressed. The Head of Procurement recounts the digitalisation of their procurement processes to transition to paperless workflows to improve transparency of operations. In general, the business is more agile in its procurement approach.
            </li>
            <li>
            Similarly, a financial director in the <span>saw milling &amp; forestry</span> industry has adopted digitised/automated workflows for the finance back office processes by implementing cloud solutions and MS Dynamics influenced by the pandemic. To that effect, the business is even expanding its IT team to meet the demands of the increasingly digital business.
            </li>
            <li>
            In the <span>healthcare sector</span>, there have been attempts to develop strategies to better connect with patients paving way for virtual patient-doctor interactions as observed by an executive procurement officer at a leading private hospital network.
            </li>
            <li>
            From a <span>consumer goods and retail</span> perspective, the some of the businesses shifted focus to cost cutting and maintaining core operational basics. This has placed digitalisation lower in the order of priorities as funds for experimentation have dried up or re-purposed. 
            </li>
          </ul>
        </Modal.Body>
      </InfoModal>
      </>
    )
}

export default CovidImpact
